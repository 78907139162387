const footerThirdMenuItems = [
  {
    title: 'facebook - Hedios Gammes H',
    slot: '<i class="icon-facebook"></i>',
    to: 'https://www.facebook.com/Hedios',
  },
  {
    title: 'linkedin - Hedios Gammes H',
    slot: '<i class="icon-linkedin"></i>',
    to: 'https://fr.linkedin.com/company/hedios-patrimoine',
  },
  {
    title: 'twitter - Hedios Gammes H',
    slot: '<i class="icon-twitter"></i>',
    to: 'https://twitter.com/Hedios_',
  },
  {
    title: 'youtube - Hedios Gammes H',
    slot: '<i class="icon-youtube"></i>',
    to: 'https://www.youtube.com/channel/UCZ56F-1p_ntdTukLi2rjg_Q/videos',
  },
];

export default footerThirdMenuItems;
