import lodash from 'lodash';
import { getDatesDiffTodayInDays } from '@/utils/date-utils';

function getContratsEnCours(contrats) {
  if (contrats.length > 0) {
    const enCours = [];
    contrats.forEach((c) => {
      if (c.statutContrat === '0') {
        enCours.push(c);
      }
    });

    const cVieCapi = lodash.orderBy(lodash.filter(enCours, (c) => (c.sousCategorie !== 'PERP')), [(item) => lodash.sumBy(item.gammesh, 'prixRevient'), 'montant'], ['desc', 'desc']);

    const cPerp = lodash.orderBy(lodash.filter(enCours, (c) => (c.sousCategorie === 'PERP')), ['montant'], ['desc']);

    return lodash.concat(cVieCapi, cPerp);
  }

  return contrats;
}

function getContratsVieEnCours(contrats) {
  if (contrats.length > 0) {
    const enCours = [];
    contrats.forEach((c) => {
      if (c.statutContrat === '0') {
        enCours.push(c);
      }
    });

    const cVieCapi = lodash.orderBy(lodash.filter(enCours, (c) => (c.sousCategorie !== 'PERP')), [(item) => lodash.sumBy(item.gammesh, 'prixRevient'), 'montant'], ['desc', 'desc']);

    return cVieCapi;
  }

  return contrats;
}

function getContratsClos(contrats) {
  const cClos = [];
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.statutContrat !== '0') {
        cClos.push(c);
      }
    });
  }

  return cClos;
}

function getAllGammesH(contrats) {
  contrats = getContratsEnCours(contrats);
  let supports = [];
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammesh.length > 0) {
        supports = (supports.length > 0) ? lodash.concat(supports, c.gammesh) : c.gammesh;
      }
    });
  }

  return (supports.length > 0) ? lodash.orderBy(supports, ['compteARebour', 'capitalInvesti'], ['asc', 'desc']) : supports;
}

function getGroupedGammesH(contrats) {
  const supports = getAllGammesH(contrats);
  const groupedSupports = [];
  if (supports.length > 0) {
    const gSupports = lodash.groupBy(supports, 'isin');
    groupedSupports.push(lodash.map(gSupports, (collection) => {
      const $return = { ...lodash.head(collection) };
      $return.montantDesinvesti = lodash.sumBy(collection, (s) => s.montantDesinvesti);
      $return.montantInvesti = lodash.sumBy(collection, (s) => s.montantInvesti);
      $return.nombreDePart = lodash.sumBy(collection, (s) => s.nombreDePart);
      $return.prixRevient = lodash.sumBy(collection, (s) => s.prixRevient);
      $return.prochainGainPotentielEuro = lodash.sumBy(collection, (s) => s.prochainGainPotentielEuro);

      return $return;
    }));

    return lodash.orderBy(lodash.head(groupedSupports), ['compteARebour', 'capitalInvesti'], ['asc', 'desc']);
  }

  return supports;
}

function getGammesHAArbitrer(contrats) {
  const gammesh = getAllGammesH(contrats);
  return lodash.filter(gammesh, (gh) => gh.arbitrable);
}

function getAllGammeshClotures(contrats) {
  let supports = [];
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammeshClotures.length > 0) {
        supports = (supports.length > 0) ? lodash.concat(supports, c.gammeshClotures) : c.gammeshClotures;
      }
    });
  }

  return supports;
}

function getGroupedGammeshClotures(contrats) {
  const supports = getAllGammeshClotures(contrats);
  const groupedSupports = [];
  if (supports.length > 0) {
    const gSupports = lodash.groupBy(supports, 'isin');
    groupedSupports.push(lodash.map(gSupports, (collection) => {
      const $return = { ...lodash.head(collection) };
      $return.gain = lodash.sumBy(collection, (s) => s.gain);
      $return.montantDesinvesti = lodash.sumBy(collection, (s) => s.montantDesinvesti);
      $return.montantInvesti = lodash.sumBy(collection, (s) => s.montantInvesti);
      $return.nombreDePart = lodash.sumBy(collection, (s) => s.nombreDePart);
      $return.prixRevient = lodash.sumBy(collection, (s) => s.prixRevient);
      $return.prochainGainPotentielEuro = lodash.sumBy(collection, (s) => s.prochainGainPotentielEuro);

      return $return;
    }));

    return lodash.orderBy(lodash.head(groupedSupports), ['dateDesinvesti', 'capitalInvesti'], ['desc', 'desc']);
  }

  return supports;
}

function getGainsGammesH(contrats) {
  let gainTotal = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      gainTotal += lodash.sumBy(c.gammesh, (s) => ((s.gain > 0) ? s.gain : 0));
      gainTotal += lodash.sumBy(c.gammeshClotures, (s) => ((s.gain > 0) ? s.gain : 0));
      gainTotal += lodash.sumBy(c.gammeshEnCoursDeRemb, (s) => ((s.gain > 0) ? s.gain : 0));
    });
  }

  return gainTotal;
}

function getGammesHAvecGain_OLD(contrats) {
  let supports = [];
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      supports = (supports.length > 0) ? lodash.concat(supports, (lodash.concat(lodash.filter(c.gammesh, (i) => (i.gain > 0)), lodash.filter(c.gammeshClotures, (i) => (i.gain > 0))))) : (lodash.concat(lodash.filter(c.gammesh, (i) => (i.gain > 0)), lodash.filter(c.gammeshClotures, (i) => (i.gain > 0))));
    });
  }

  const groupedSupports = [];
  if (supports.length > 0) {
    const gSupports = lodash.groupBy(supports, 'isin');
    groupedSupports.push(lodash.map(gSupports, (collection) => {
      const $return = {
        ...lodash.head(collection),
      };
      $return.gain = lodash.sumBy(collection, (s) => s.gain);
      $return.montantDesinvesti = lodash.sumBy(collection, (s) => s.montantDesinvesti);
      $return.montantInvesti = lodash.sumBy(collection, (s) => s.montantInvesti);
      $return.nombreDePart = lodash.sumBy(collection, (s) => s.nombreDePart);
      $return.prixRevient = lodash.sumBy(collection, (s) => s.prixRevient);
      $return.prochainGainPotentielEuro = lodash.sumBy(collection, (s) => s.prochainGainPotentielEuro);

      return $return;
    }));

    return lodash.orderBy(lodash.head(groupedSupports), ['dateDesinvesti', 'capitalInvesti'], ['desc', 'desc']);
  }

  return supports;
}

function getGammesHAvecGain(gh, ghClos, ghEnCoursDeRemb) {
  let supports = [];

  if (![undefined, null, false].includes(gh)) {
    if ((typeof gh === 'object') && (gh.gain > 0)) {
      supports.push(gh);
    } else if ((Array.isArray(gh)) && (gh.length > 0)) {
      supports = lodash.filter(gh, (i) => (i.gain > 0));
    }
  }

  if (![undefined, null, false].includes(ghClos)) {
    if ((typeof ghClos === 'object') && (ghClos.gain > 0)) {
      supports.push(ghClos);
    } else if ( (Array.isArray(ghClos)) && (ghClos.length > 0)) {
      supports = (supports.length > 0) ? lodash.concat(supports, lodash.filter(ghClos, (i) => (i
        .gain > 0))) : lodash.filter(ghClos, (i) => (i.gain > 0));
    }
  }

  if (![undefined, null, false].includes(ghEnCoursDeRemb)) {
    if ((typeof ghEnCoursDeRemb === 'object') && (ghEnCoursDeRemb.gain > 0)) {
      supports.push(ghEnCoursDeRemb);
    } else if ((Array.isArray(ghEnCoursDeRemb)) && (ghEnCoursDeRemb.length > 0)) {
      supports = (supports.length > 0) ? lodash.concat(supports, lodash.filter(ghEnCoursDeRemb, (
            i) => (i
        .gain > 0))): lodash.filter(ghEnCoursDeRemb, (i) => (i.gain > 0));
    }
  }

  return supports;
}

function getAllGammesHEnCoursDeRemb(contrats) {
  contrats = getContratsEnCours(contrats);
  let supports = [];
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammeshEnCoursDeRemb.length > 0) {
        supports = (supports.length > 0) ? lodash.concat(supports, c.gammeshEnCoursDeRemb) : c
          .gammeshEnCoursDeRemb;
      }
    });
  }

  return supports;
}

function getGroupedGHEnCoursDeRemb(contrats) {
  const supports = getAllGammesHEnCoursDeRemb(contrats);
  const groupedSupports = [];
  if (supports.length > 0) {
    const gSupports = lodash.groupBy(supports, 'isin');
    groupedSupports.push(lodash.map(gSupports, (collection) => {
      const $return = { ...lodash.head(collection) };
      $return.montantDesinvesti = lodash.sumBy(collection, (s) => s.montantDesinvesti);
      $return.montantInvesti = lodash.sumBy(collection, (s) => s.montantInvesti);
      $return.nombreDePart = lodash.sumBy(collection, (s) => s.nombreDePart);
      $return.prixRevient = lodash.sumBy(collection, (s) => s.prixRevient);
      $return.prochainGainPotentielEuro = lodash.sumBy(collection, (s) => s
        .prochainGainPotentielEuro);

      return $return;
    }));

    return lodash.orderBy(lodash.head(groupedSupports), ['compteARebour', 'capitalInvesti'], ['asc', 'desc']);
  }

  return supports;
}

function getTotalGammesH(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammesh.length > 0) {
        total += lodash.sumBy(c.gammesh, 'prixRevient');
      }
    });
  }

  return total;
}

function getTotalProchainsGains(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammesh.length > 0) {
        total += lodash.sumBy(c.gammesh, (g) => (g.prochainGainPotentielEuro - g.montantInvesti));
      }
    });
  }

  return total;
}

function getTotalGammesHMontantInvesti(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammesh.length > 0) {
        total += lodash.sumBy(c.gammesh, (g) => g.montantInvesti);
      }
    });
  }

  return total;
}

function getTotalGammesHEnCoursDeRemb(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.gammeshEnCoursDeRemb.length > 0) {
        total += lodash.sumBy(c.gammeshEnCoursDeRemb, 'prixRevient');
      }
    });
  }

  return total;
}

function getGammesHProchainGain(contrats) {
  const gammesh = getGroupedGammesH(contrats);
  if (gammesh.length === 0) {
    return null;
  }

  return lodash.head(lodash.orderBy(gammesh, ['compteARebours', 'montantInvesti', 'dateInvesti'], ['desc', 'desc', 'asc']));
}

function getAllcontratsAvecMandat(contrats) {
  contrats = getContratsEnCours(contrats);
  const $return = [];
  if (contrats.length > 0) {
    for (let i = 0; i < contrats.length; i += 1) {
      if (contrats[i].haveMandat) {
        $return.push(contrats[i]);
      }
    }
  }

  return $return;
}

function haveMandatGammesH(contrats) {
  return (getAllcontratsAvecMandat(contrats).length > 0);
}

function getNombreDeMandatGammesH(contrats) {
  contrats = getContratsEnCours(contrats);
  let nombre = false;
  if (contrats.length > 0) {
    for (let i = 0; i < contrats.length; i += 1) {
      if (contrats[i].haveMandat) {
        nombre += 1;
      }
    }
  }

  return nombre;
}

function getMontantTotalSousMandat(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.haveMandat) {
        total += lodash.sumBy(c.gammesh, 'prixRevient');
        if (![undefined, null, false, ''].includes(c.mandat.enAttenteArbitrage) && (c.mandat.enAttenteArbitrage > 0)) {
          total += parseFloat(c.mandat.enAttenteArbitrage);
        }
      }
    });
  }

  return total;
}

function getMontantTotalFondsDisponibles(contrats) {
  contrats = getContratsEnCours(contrats);
  let total = 0;
  if (contrats.length > 0) {
    contrats.forEach((c) => {
      if (c.haveMandat) {
        if ((c.mandat.enAttenteArbitrage != null) && (c.mandat.enAttenteArbitrage !== undefined)) {
          total += parseFloat(c.mandat.enAttenteArbitrage);
        }
      }
    });
  }

  return total;
}

function getOperationValideesChezHedios(ops) {
  const $return = [];
  ops.forEach((op) => {
    if ((op.typeActe !== 'KYC') && (op.dateSignature) && (getDatesDiffTodayInDays(op.dateSignature) <= 4)) {
      $return.push(op);
    }
  });

  return $return;
}

function getContratsLiquidites(contrats) {
  contrats = getContratsVieEnCours(contrats);
  const totalGH = getTotalGammesH(contrats);
  const enCoursTotal = lodash.sumBy(contrats, 'montant');

  return (enCoursTotal - totalGH);
}

function getAllowEditInfoPers(sfUser) {
  if (sfUser && sfUser.kyc && sfUser.kyc.identifiantExterne !== null) {
    if (['05', '10'].includes(sfUser.kyc.gelule)) {
      return true;
    }
  }

  return false;
}

/**
 * Client détenant un contrat PERP et aucun contrat PER
 *
 * @param {*} sfUser
 * @return Boolean
 */
function havePERNotif1(sfUser) {
  const contratsPERP = lodash.filter(sfUser.contratsVie, { niveau5: 'Hedios Life PERP', statutContrat: '0'});
  const contratsPER = lodash.filter(sfUser.contratsVie, { niveau5: 'Suravenir PER', statutContrat: '0'});

  if((contratsPERP.length > 0) && (contratsPER.length === 0)) {
    return true;
  }

  return false;
}

/**
 * Client détenant un contrat PERP et un contrat PER
 *
 * @param {*} sfUser
 * @return Boolean
 */
function havePERNotif2(sfUser) {
  const contratsPER = lodash.filter(sfUser.contratsVie, { niveau5: 'Suravenir PER', statutContrat: '0'});
  const contratsPERP = lodash.filter(sfUser.contratsVie, { niveau5: 'Hedios Life PERP', statutContrat: '0'});

  if((contratsPERP.length > 0) && (contratsPER.length > 0)) {
    return true;
  }

  return false;
}

/**
 * Client dont segmet 'Client GH' et n'ayant pas de contrat Suravenir PER
 *
 * @param {*} sfUser
 * @return Boolean
 */
function havePERNotif3(sfUser) {
  const contratsPER = lodash.filter(sfUser.contratsVie, { niveau5: 'Suravenir PER', statutContrat: '0'});

  if((contratsPER.length === 0) && ((sfUser.segment === 'Client GH') || (sfUser.segment === 'Client MGH'))) {
    return true;
  }

  return false;
}

/**
 * Client dont segmet 'Client GH' et détenant au moins un contrat Suravenir PER
 *
 * @param {*} sfUser
 * @return Boolean
 */
function havePERNotif4(sfUser) {
  const contratsPER = lodash.filter(sfUser.contratsVie, { niveau5: 'Suravenir PER', statutContrat: '0'});

  if((contratsPER.length > 0) && ((sfUser.segment === 'Client GH') || (sfUser.segment === 'Client MGH'))) {
    return true;
  }

  return false;
}

export {
  getAllGammesH,
  getGroupedGammesH,
  getAllGammeshClotures,
  getGroupedGammeshClotures,
  getGainsGammesH,
  getGammesHAvecGain,
  getAllGammesHEnCoursDeRemb,
  getGroupedGHEnCoursDeRemb,
  getTotalGammesH,
  getTotalGammesHEnCoursDeRemb,
  getGammesHProchainGain,
  getAllcontratsAvecMandat,
  haveMandatGammesH,
  getMontantTotalSousMandat,
  getMontantTotalFondsDisponibles,
  getNombreDeMandatGammesH,
  getGammesHAArbitrer,
  getOperationValideesChezHedios,
  getContratsEnCours,
  getContratsVieEnCours,
  getContratsClos,
  getContratsLiquidites,
  getTotalGammesHMontantInvesti,
  getTotalProchainsGains,
  getAllowEditInfoPers,
  havePERNotif1,
  havePERNotif2,
  havePERNotif3,
  havePERNotif4,
};
