import { createApp } from 'vue';

import {
  drupalAutHTTP,
  drupalHTTP,
  sfAutHTTP,
  sfNativeWSHTTP,
  sfCustomWSHTTP,
  mulesoftHTTP
} from '@/axios/index';

import {
  isDrupalSessionActive,
  getHediosLocalStorage,
  removeAllLocalStorage,
  removeSFLocalStorage,
  getSFLocalStorage,
  handleAllSalesforceWS,
  drupalInjectRequestHeader,
  sfInjectRequestHeader,
} from '@/store/modules/functions-utils';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';

router.beforeEach((to, from, next) => {
  store.dispatch('login_drupal');

  const metaTag = to.matched.slice().reverse().find((record) => record.meta && record.meta.title);

  if (metaTag) {
    document.title = metaTag.meta.title;
  }

  next();
});

// Drupal autentification interceptor request
drupalAutHTTP.interceptors.request.use(
  (request) => {
    if (isDrupalSessionActive()) {
      const drupalStorage = getHediosLocalStorage();
      store.commit('LOGIN_USER', drupalStorage);
      store.dispatch('login_sf', drupalStorage.sf_jwt);

      return new Promise(() => {});
    }

    removeAllLocalStorage();
    return request;
  },
);

// Drupal autentification interceptor response
drupalAutHTTP.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      store.dispatch('login_sf', response.data.sf_jwt);
    }
    return response;
  },
);

// Salesforce autentification interceptor request
sfAutHTTP.interceptors.request.use(
  (request) => {
    const sfStorage = getSFLocalStorage();
    if (sfStorage != undefined && sfStorage.access_token && store.getters.idFicheContact && store.getters.codeParrain) {
      store.commit('LOGIN_SF', sfStorage);
      handleAllSalesforceWS(store);

      return new Promise(() => {});
    }

    removeSFLocalStorage();
    return request;
  },
);

// Salesforce autentification interceptor
sfAutHTTP.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      store.commit('LOGIN_SF', response.data);
      handleAllSalesforceWS(store);
    }
    return response;
  },
);

// Salesforce native webservice request interceptor
sfNativeWSHTTP.interceptors.request.use(
  (request) => {
    if (store.getters.sfAuthorization) {
      return sfInjectRequestHeader(request, store);
    }

    return new Promise(() => {});
  },
  (error) => {
    Promise.reject(error);
  },
);

// Salesforce custom webservice request interceptor
sfCustomWSHTTP.interceptors.request.use(
  (request) => {
    if (store.getters.sfAuthorization) {
      return sfInjectRequestHeader(request, store);
    }

    return new Promise(() => {});
  },
  (error) => {
    Promise.reject(error);
  },
);

//Drupal request interceptor
drupalHTTP.interceptors.request.use(
  (request) => {
    return drupalInjectRequestHeader(request, store);
  },
  (error) => {
    Promise.reject(error);
  },
);

//Mulesoft app web service
mulesoftHTTP.interceptors.request.use(
  (request) => {
    request.headers['x-csrf-token'] = store.getters.servicesToken;
    request.headers['x-sessid'] = store.getters.sessid;
    request.headers['x-id-fc'] = store.getters.idFicheContact;

    if(process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'preproduction') {
      request.headers['x-dev-key'] = process.env.VUE_APP_MULE_API_DEV_KEY;
    }

    request.headers['client_id'] = process.env.VUE_APP_MULESOFT_CLIENT_ID;
    request.headers['client_secret'] = process.env.VUE_APP_MULESOFT_CLIENT_SECRET;

    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#global');
