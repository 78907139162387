<template>
  <header
    id="mast"
    class="mast"
  >
    <div class="container-fluid">
      <div id="logo">
        <a :href="websiteLink">
          <img
            :class="{ logoMob: isMobile }"
            src="~@/assets/img/logo.svg"
            width="382"
            height="87"
            alt="Hedios"
          >
        </a>
      </div>
      <header-nav />
    </div>
    <hedios-menu />
  </header>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';
import jQuery from 'jquery';
import HeaderNav from '@/components/header/HeaderNav.vue';
import HediosMenu from '@/components/header/HediosMenu.vue';

export default {
  name: 'HediosHeader',
  components: {
    'header-nav': HeaderNav,
    'hedios-menu': HediosMenu,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
    };
  },
  computed: {
    websiteLink() {
      return process.env.VUE_APP_DRUPAL_BASE_URL;
    },
  },
  mounted() {
    const navbar = jQuery('#mast');
    let c = 0;
    let currentScrollTop = 0;

    jQuery(window).scroll(() => {
      const a = jQuery(window).scrollTop();
      const b = navbar.height() - 100;

      currentScrollTop = a;

      if (c < currentScrollTop && a > b + b) {
        navbar.addClass('scrollUp');
      } else if (c > currentScrollTop && !(a <= b)) {
        navbar.removeClass('scrollUp');
      }
      c = currentScrollTop;
    });
  },
};
</script>

<style lang="scss">

#global {
  @media screen and (max-width: $breakpoint-xs) {
    padding-top: 100px;
  }
}

#mast {
  border-top: 5px solid $accent-1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  @include transition(0.15s);
  @media screen and (max-width: $breakpoint-xs) {
    box-shadow: none;
  }
  .container {
    position: relative;
  }
  #logo {
    display: flex;
    height: 95px;
    float: left;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      max-width: 179px;
      min-width: 179px;
    }
  }
  .logoMob {
    display: none;
  }
  a {
    font-family: $font-reg;
    color: $black;
    text-decoration: none;
    font-size: 0.9em;
  }

  &.scrollUp {
    transform: translateY(-100px);
  }

  @media screen and (max-width: $breakpoint-m) {
    position: fixed;
    top: 0;
    width: 100%;
    height: 106px;
    z-index: 10;
    background: #ffffff;
    #logo {
      width: auto;
      justify-content: center;
      height: auto;
      padding: 13px 0;
      img {
        display: none;
        max-width: 155px;
        &.logoMob {
          display: inline-block;
          min-width: 1px;
        }
      }
    }
    &.scrollUp {
      transform: translateY(-80px);
    }
    .blk-access a {
      color: $white;
    }
  }

}

.copyright {
  padding: 10px 0;
  background-color: $accent-5;
  margin-top: 15px;
}

/* Menu compte */
#mast {
  .blk {
    &-menu-ec {
      background: $accent-4;
      @include transition(0.155s);
      min-height: 50px;
      display: flex;
      align-items: center;
      font-family: $font-light;
      letter-spacing: 0.075em;
      > .container-fluid {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .blk-name {
        width: 20%;
        color: $white;
      }
      nav {
        width: 80%;
        padding-right: 10%;
        &.isMobile {
          width: 100%;
          ul {
            display: inline-block;
            width: 100%;
            a {
              text-align: left;
            }
          }
        }
        ul {
          display: flex;
          width: 100%;
        }
        li {
          display: inline-block;
          color: $white;
          text-transform: uppercase;
          line-height: normal;
          text-align: center;
          margin: 0 1.5%;
        }
        a {
          color: $accent-24;
          padding: 10px;
          display: inline-block;
          font-family: $font-light;
        }
        .active {
          position: relative;
          a {
            color: $white;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 18px solid $accent-4;
            position: absolute;
            bottom: -18px;
            left: calc(50% - 12px);
          }
        }
      }

      .js-btn-mob {
        display: none;
        &.isTabOrMob{
          display: inline-block;
          width: 100%;
          float: left;
        }
      }
      .js-btn-menu {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        float: left;
        height: 30px;
        margin: 13px 0 0 10px;
        span {
          display: block;
          width: 36px;
          height: 32px;
          margin: 0 auto;
          position: relative;
          top: 0;
          @include transition(0.25s);
        }
        span:before, span:after {
          position: absolute;
          content: '';
        }
        span, span:before, span:after {
          width: 36px;
          height: 3px;
          background-color: $white;
          display: block;
          @include transition(0.25s);
          @include border-radius(4px);
        }
        span:before {
          margin-top: -12px;
        }
        span:after {
          margin-top: 12px;
        }
        &.opened {
          span {
            background-color: rgba(0,0,0,0.0);
            @include transition(0.1s);
          }
          span:before {
            margin-top: 0;
            -webkit-transform: rotate(45deg); transform: rotate(45deg);
          }
          span:after {
            margin-top: 0;
            -webkit-transform: rotate(-45deg); transform: rotate(-45deg);
          }
        }
      }
      .js-btn-action-rap{
        .textRapAfter {
          display: block;
          width: 36px;
          height: 32px;
          margin: 0 auto;
          position: relative;
          top: 0;
          @include transition(0.25s);
        }
        .textRapAfter:before, .textRapAfter:after {
          position: absolute;
          content: '';
        }
        .textRapAfter, .textRapAfter:before, .textRapAfter:after {
          width: 36px;
          height: 3px;
          background-color: $white;
          display: block;
          @include transition(0.25s);
          @include border-radius(4px);
        }
        .textRapAfter:before {
          margin-top: -12px;
        }
        .textRapAfter:after {
          margin-top: 12px;
        }
        .textRap{
          opacity: 1;
        }
        .textRapAfter{
          display: none;
        }
        &.opened {
          .textRap{
            opacity: 0;
            width: 0;
          }
          .textRapAfter {
            background-color: rgba(0,0,0,0.0);
            @include transition(0.1s);
            display: block;
            width: 48px;
            height: 30px;
          }
          .textRapAfter:before {
            margin-top: 0;
            -webkit-transform: rotate(45deg); transform: rotate(45deg);
          }
          .textRapAfter:after {
            margin-top: 0;
            -webkit-transform: rotate(-45deg); transform: rotate(-45deg);
          }
        }
      }
      @media screen and (max-width: $breakpoint-m) {
        min-height: 1px;
        position: relative;
        > .container-fluid {
          padding-left: 0;
          padding-right: 0;
          display: inline-block;
        }
        .blk-name {
          display: none;
        }
        nav {
          padding: 0;
          position: absolute;
          top: 100%;
          width: 100%;
          background-color: $accent-4;
          ul {
            display: inline-block;
            width: 100%;
          }
          li {
            display: inline-block;
            width: 100%;
            padding: 4px 20px;
            border-bottom: 1px solid $accent-24;
            text-align: left;
            margin: 0;
            &:last-child {
              border-bottom: none;
            }
            br {
              display: none;
            }
            a {
              width: 100%;
            }
          }
          .active:after {
            display: none;
          }
        }
      }
    }
  }
  .head-menu {
    float: right;
    padding-right: 4.5%;
    position: relative;
    li {
      display: inline-block;
      text-align: center;
      padding: 0 15px;
      span {
        display: block;
        width: 100%;
      }
    }
    .header-logos a {
      color: $accent-2;
      height: 95px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $breakpoint-s) {
        display: unset;
        height: unset;
      }
    }
    i {
      font-size: 2.35em;
      position: relative;
      &.icon-ic_mail {
        font-size: 2.65em;
      }
      em {
        position: absolute;
        background-color: $notif-1;
        padding: 2px;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 12px;
        font-style: normal;
        @include border-radius(50%);
        top: -5px;
        right: -10px;
        font-family: $font-semi;
      }
    }
    .menu-tel {
      background-color: $accent-1;
      a {
        color: $white;
      }
    }
    .head-submenu {
      display: none;
      position: absolute;
      @include box-shadow(0px 0px 6px 2px rgba(0,0,0,.1));
      background-color: $white;
      z-index: 1;
      top: calc(100% + -5px);
      right: 25px;
      padding: 8px 0;
      width: 150px;
      li {
        padding: 5px;
        display: inline-block;
        width: 100%;
        a {
          color: $black;
        }
      }
    }
    @media screen and (max-width: $breakpoint-s) {
      padding-top: 18px;
      padding-right: 0;
      li {
        &:first-child {
          position: relative;
          top: 5px;
        }
        span {
          display: none;
        }
        a {
          height: unset;
          display: inline-block;
          height: unset;
        }
      }
      .head-submenu {
        right: -15px;
      }
      .menu-tel {
        display: none;
      }
    }
  }
}

.js-btn-action-rap {
  float: right;
  display: flex;
  align-items: center;
  padding: 15px 35px;
  background: $accent-2;
  border: none;
  cursor: pointer;
  color: $white;
  font-size: 1.25em;
  font-family: $font-reg;
}

.blk-name {
  &._mob {
    display: none;
    @media screen and (max-width: $breakpoint-m) {
      display: inline-block;
      padding: 45px 10px 15px 10px;
      text-align: center;
      width: 100%;
    }
    @media screen and (max-width: $breakpoint-xs) {
      padding: 0 10px 15px 10px;
    }
    @media screen and (min-width: $breakpoint-s) and (max-width:$breakpoint-m) {
      padding: 25px 10px 15px 10px;
    }
  }
}
.logoMob{
  @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : landscape) {
    display: inline-block !important;
    min-width: 1px;
  }
}
</style>
