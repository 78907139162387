import { drupalHTTP } from '@/axios/index';

const supportedBrowsers = /(Edge?\/(104(\.0|)|104(\.([1-9]|\d{2,})|)|(10[5-9]|1[1-9]\d|[2-9]\d\d|\d{4,})(\.\d+|))(\.\d+|))|(Firefox\/(102\.0|102\.([1-9]|\d{2,})|(10[3-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+|104\.0|104\.([1-9]|\d{2,})|(10[5-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Chrom(ium|e)\/(103\.0|103\.([1-9]|\d{2,})|(10[4-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Version\/(15\.6|15\.([7-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+|16\.0|16\.([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})\.\d+)([.,]\d+|) Safari\/)|(Chrome.+OPR\/(90\.0|90\.([1-9]|\d{2,})|(9[1-9]|\d{3,})\.\d+)\.\d+)|((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(14[_.]5|14[_.]([6-9]|\d{2,})|14[_.]8|14[_.](9|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+|15[_.]5|15[_.]([6-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})[_.]\d+|16[_.]0|16[_.]([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})[_.]\d+)([_.]\d+|))|(Android:?[ \-/](106(\.0|)|106(\.([1-9]|\d{2,})|)|(10[7-9]|1[1-9]\d|[2-9]\d\d|\d{4,})(\.\d+|))(\.\d+|))|(Mobile Safari.+OPR\/(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+)\.\d+)|(Android.+Firefox\/(105\.0|105\.([1-9]|\d{2,})|(10[6-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Android.+Chrom(ium|e)\/(106\.0|106\.([1-9]|\d{2,})|(10[7-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+)(\.\d+|))|(Android.+(UC? ?Browser|UCWEB|U3)[ /]?(13\.4|13\.([5-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+)\.\d+)|(SamsungBrowser\/(17\.0|17\.([1-9]|\d{2,})|(1[8-9]|[2-9]\d|\d{3,})\.\d+))|(Android.+MQQBrowser\/(13(\.1|)|13(\.([2-9]|\d{2,})|)|(1[4-9]|[2-9]\d|\d{3,})(\.\d+|))(\.\d+|))|(K[Aa][Ii]OS\/(2\.5|2\.([6-9]|\d{2,})|([3-9]|\d{2,})\.\d+)(\.\d+|))/;

const emailMatch = /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

const frenchPhone = /(^0|\+33)([1-9]{1}([0-9]{8})$)/;
const internationalPhone = /^(00|\+)(\d{4,20})$/;
const FDS_SO_ISIN = 'SUR000001766';
const FDS_SR_ISIN = 'SUR000001767';
const FDS_SR2_ISIN = 'SUR000004629';
const FDS_ISINS = [FDS_SO_ISIN, FDS_SR_ISIN, FDS_SR2_ISIN];
const MGH_MINIMUM_MTT_UNITAIRE = 1000;

function isFondEuro(isin) {
  return FDS_ISINS.includes(isin);
}

function hediosFormatNumber(arg) {
  if ([undefined, null, false, '', 's'].includes(arg) || (arg === 0)) {
    return 0;
  }
  /* if (arg % 1 === 0) {
    return Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(arg);
  } */

  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(arg);
}

function hediosFormatNumber4(arg) {
  if ([undefined, null, false, '', 's'].includes(arg) || (arg === 0)) {
    return 0;
  }

  /* if (arg % 1 === 0) {
    return Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(arg);
  } */

  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(arg);
}

function hediosFormatNumberMax4(arg) {
  if ([undefined, null, false, '', 's'].includes(arg) || (arg === 0)) {
    return 0;
  }

  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(arg);
}

function hediosFormatNumberChart(arg) {
  if ([undefined, null, false, '', 's'].includes(arg) || (arg === 0)) {
    return 0;
  }

  /* if (arg % 1 === 0) {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(arg);
  } */

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(arg);
}

function printGammesHTable($ref) {
  const element = window.document.getElementById($ref);
  if (!element) {
    // console.error(`Element to print #${$ref} not found!`);
  } else {
    const name = '_blank';
    let specs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
    const replace = true;

    specs = specs.join(',');

    const url = '';
    const win = window.open(url, name, specs, replace);

    win.document.write(`
      <html>
        <head>
          <title>${window.document.title}</title>
          <style type='text/css'>
            @media print {
              @page {
                size: landscape;
              }
            }
            .btn-voir-details, .btn-print {
              display: none;
            }
            table {
              width: 100% ;
              margin-top: 15px;
              border-collapse: collapse;
            }
            tr {
              border: none;
              border-bottom: solid 1px #dddddd;
            }
            tr:last-child {
              border-bottom: none;
            }
            th, td {
              text-align: center;
              padding: 8px 5px;
              &:first-child {
                text-align: left;
              }
            }
            th:first-child, td:first-child {
              text-align: left;
            }
            th {
              font-family: "SourceSansPro-Semibold", sans-serif;
              border-top: none;
            }

            tr.row-details table {
              display: table;
              background: #eceff1;
              margin: 0;
              font-size: 0.85em;
            }
            tr.row-details.active table tr {
              border-bottom: none;
            }
            tr.row-details.active table tr td {
              border: none;
              padding: 10px;
              background: #eceff1;
            }
          </style>
        </head>
        <body>
          ${element.innerHTML}
        </body>
      </html>
    `);

    setTimeout(() => {
      win.document.close();
      win.focus();
      win.print();
      win.close();
    }, 1000);
  }
}

function isNumber(n) {
  return (!Number.isNaN(parseFloat(n)) && Number.isFinite(n));
}

function hediosSendMail(sujet, contenu, key, to, from) {
  const params = new URLSearchParams();
  params.append('subject', sujet);
  params.append('content', contenu);
  if (![undefined, null, false, ''].includes(key)) {
    params.append('key', key);
  }
  if (![undefined, null, false, ''].includes(to)) {
    params.append('to', to);
  }
  if (![undefined, null, false, ''].includes(from)) {
    params.append('from', from);
  }

  return drupalHTTP.post(
    'sendmail',
    params,
    {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    },
  );
}

function operationTitle(typeActe) {
  let acte = '';
  switch (typeActe) {
    case 'Hedios Life':
    case 'souscription':
    case 'souscription_mandat':
    case 'souscription_mgh':
    case 'souscription_mgh_arbitrer':
    case 'souscription_mgh_ni_verser_ni_arbitrer':
    case 'souscription_mgh_verser':
    case 'souscription_mgh_aug_capitaux_verser':
    case 'souscription_mgh_aug_capitaux_arbitrer':
      acte = 'Souscription';
      break;
    case 'arbitrage':
    case 'proposition':
    case 'proposition_arbitrage':
    case 'proposition_mgh':
    case 'proposition_gh_so':
    case 'proposition_gh_so_uc':
    case 'proposition_arbitrage_mgh_sans_unitaire':
    case 'proposition_tpamgh':
    case 'proposition_gh_gh':
    case 'validation_arbitrage':
      acte = 'Arbitrage';
      break;
    case 'versement':
    case 'proposition_versement_mgh':
      acte = 'Versement';
      break;
    case 'rachat_total':
      acte = 'Rachat total';
      break;
    case 'rachat_partiel':
      acte = 'Rachat partiel';
      break;
    case 'kyc':
      acte = 'Données à certifier';
      break;
    case 'Mandat Gammes H':
      acte = 'Mandat Gammes H';
      break;
    default:
      acte = 'Opération en cours';
  }

  return acte;
}

function isFrenchPhoneNumber(phone) {
  return ((/(^\+33)/).test(phone) || (/(^0)([1-9]){1}/).test(phone));
}

function isValidPhoneNumber(phone) {
  if ([undefined, null, false, ''].includes(phone)) {
    return false;
  }
  phone = phone.replaceAll(' ', '');
  if (phone.length > 4) {
    if (isFrenchPhoneNumber(phone) && (frenchPhone).test(phone)) {
      return true;
    }

    if (!isFrenchPhoneNumber(phone) && (internationalPhone).test(phone)) {
      return true;
    }
  }

  return false;
}

function modeDeReglement(modeRglmt) {
  let val = '';
  switch (modeRglmt) {
    case 'C':
      val = 'chèque';
      break;
    case 'P':
      val = 'prélèvement';
      break;
    case 'V':
    case 'E':
      val = 'virement';
      break;
    default:
      val = modeRglmt;
  }

  return val;
}

function formatEvolutionIndice(evolution) {
  const valFormat = this.hediosFormatNumber(evolution);
  if (evolution > 0) {
    return `+${valFormat}`;
  }

  return valFormat;
}

function formatSeuilDeRemboursement(seuilDeRemboursement) {
  const valFormat = this.hediosFormatNumber(seuilDeRemboursement);
  if (seuilDeRemboursement > 0) {
    return `+${valFormat}`;
  }
  if (seuilDeRemboursement < 0) {
    return `-${valFormat}`;
  }

  return valFormat;
}

function isContratPerp(refCtt) {
  return refCtt.startsWith('95');
}

function isContratPer(refCtt) {
  return refCtt.startsWith('B8');
}

function isTUnderscore(drupalLogin) {
  return drupalLogin.startsWith('t_');
}

function hediosCapitalize(word) {
  return word
    .split(' ')
    // .map((letter, index) => (index ? letter.toLowerCase() : letter.toUpperCase()))
    .map((letter) => (letter[0].toUpperCase() + letter.substring(1).toLowerCase()))
    .join(' ');
}

function isValidEmail(val) {
  return emailMatch.test(val);
}

export {
  supportedBrowsers,
  emailMatch,
  frenchPhone,
  internationalPhone,
  FDS_ISINS,
  FDS_SO_ISIN,
  FDS_SR_ISIN,
  MGH_MINIMUM_MTT_UNITAIRE,
  isFondEuro,
  hediosFormatNumber,
  hediosFormatNumber4,
  hediosFormatNumberMax4,
  hediosFormatNumberChart,
  printGammesHTable,
  isNumber,
  hediosSendMail,
  operationTitle,
  isValidPhoneNumber,
  isFrenchPhoneNumber,
  modeDeReglement,
  formatEvolutionIndice,
  formatSeuilDeRemboursement,
  isContratPerp,
  hediosCapitalize,
  isValidEmail,
  isTUnderscore,
  isContratPer,
};
