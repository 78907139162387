<template>
  <div>
    <div
      class="alert alert-danger"
      role="alert"
    >
      <!-- <a href="#" class="close" @click.stop.prevent="closeAlert(index, $event)">&times;</a> -->
      <template v-if="isTUnderscore(drupalLogin)">
        <p>{{ $t('tUnderscoreMsg') }}</p>
        <p>{{ $t('contactUsMsg') }}</p>
      </template>
      <template v-else>
        <p
          v-for="(error, index) in errors"
          :key="index"
          class="col-12"
        >
          <span>{{ index }} - {{ error }}</span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isTUnderscore } from '@/utils/index';

export default {
  name: 'ErrorAlert',
  props: {
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isTUnderscore,
    };
  },
  computed: {
    ...mapGetters(['drupalLogin']),
  },
  methods: {
    closeAlert(key, event) {
      // eslint-disable-next-line vue/no-mutating-props
      delete this.errors[key];
      event.target.parentNode.style.display = 'none';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
