import {
  sfCustomWSHTTP,
} from '@/axios';
import { tunnelsInitialState } from '@/store/modules/functions-utils';

const tunnels = {
  state: () => (tunnelsInitialState()),
  mutations: {
    INIT_TUNNEL_ERROR(state) {
      state.tErrors = [];
    },
    INIT_TUNNELS(state) {
      state.tunnels = {
        souscriptions: [],
        souscriptionsM: [],
        arbitrages: [],
        versements: [],
        rachats: [],
        kyc: [],
        propositionA: [],
        propositionEnCoursDeVie: [],
        validationA: [],
        autresTunnels: [],
      };
    },
    ADD_TUNNEL_ERROR(state, error) {
      state.tErrors.push(error);
    },
    ADD_SOUSCRIPTIONS(state, tunnel) {
      state.tunnels.souscriptions.push(tunnel);
    },
    ADD_SOUSCRIPTIONS_MANDAT(state, tunnel) {
      state.tunnels.souscriptionsM.push(tunnel);
    },
    ADD_ARBITRAGES(state, tunnel) {
      state.tunnels.arbitrages.push(tunnel);
    },
    ADD_VERSEMENTS(state, tunnel) {
      state.tunnels.versements.push(tunnel);
    },
    ADD_RACHATS(state, tunnel) {
      state.tunnels.rachats.push(tunnel);
    },
    ADD_KYC(state, tunnel) {
      state.tunnels.kyc.push(tunnel);
    },
    ADD_PROPOSITION_ARBITRAGE(state, tunnel) {
      state.tunnels.propositionA.push(tunnel);
    },
    ADD_PROPOSITION_EN_COURS_DE_VIE(state, tunnel) {
      state.tunnels.propositionEnCoursDeVie.push(tunnel);
    },
    ADD_VALIDATION_ARBITRAGE(state, tunnel) {
      state.tunnels.validationA.push(tunnel);
    },
    ADD_TUNNELS_GH_GH_CLOS(state, payload) {
      state.tunnels.tunnelsGHGHClos = payload;
    },
    ADD_OTHERS(state, tunnel) {
      state.tunnels.autresTunnels.push(tunnel);
    },
    DELETE_TUNNEL(state, infos) {
      state.tunnels[infos.categ].splice(infos.index, 1);
    },
  },
  actions: {
    load_client_tunnels(context, args) {
      context.commit('INIT_TUNNEL_ERROR');
      context.commit('INIT_TUNNELS');

      sfCustomWSHTTP.get(`${args.idFicheContact}/tunnel/`)
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((tunnel) => {
              switch (tunnel.typeActe) {
                case 'souscription':
                  context.commit('ADD_SOUSCRIPTIONS', tunnel);
                  break;
                case 'souscription_mgh':
                case 'souscription_mgh_arbitrer':
                case 'souscription_mgh_ni_verser_ni_arbitrer':
                case 'souscription_mgh_verser':
                case 'souscription_mgh_aug_capitaux_verser':
                case 'souscription_mgh_aug_capitaux_arbitrer':
                  context.commit('ADD_SOUSCRIPTIONS_MANDAT', tunnel);
                  break;
                case 'arbitrage':
                  context.commit('ADD_ARBITRAGES', tunnel);
                  break;
                case 'versement':
                  context.commit('ADD_VERSEMENTS', tunnel);
                  break;
                case 'rachat_total':
                case 'rachat_partiel':
                  context.commit('ADD_RACHATS', tunnel);
                  break;
                case 'kyc':
                  context.commit('ADD_KYC', tunnel);
                  break;
                case 'proposition':
                case 'proposition_gh_so':
                case 'proposition_gh_so_uc':
                case 'proposition_versement_mgh':
                case 'proposition_arbitrage_mgh_sans_unitaire':
                case 'proposition_tpamgh':
                  context.commit('ADD_PROPOSITION_ARBITRAGE', tunnel);
                  break;
                case 'proposition_gh_gh':
                  context.commit('ADD_PROPOSITION_EN_COURS_DE_VIE', tunnel);
                  break;
                case 'validation_arbitrage':
                  context.commit('ADD_VALIDATION_ARBITRAGE', tunnel);
                  break;

                default:
                  context.commit('ADD_OTHERS', tunnel);
              }
            });
          } else {
            context.commit('ADD_TUNNEL_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_TUNNEL_ERROR', error);
        });
    },
    load_tunnels_proposition_gh_gh_clos(context, args) {

      sfCustomWSHTTP.get(`${args.idFicheContact}/tunnel/?acte=proposition_gh_gh&status=delete_bo`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('ADD_TUNNELS_GH_GH_CLOS', response.data);
          } else {
            context.commit('ADD_TUNNEL_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_TUNNEL_ERROR', error);
        });
    },
    delete_client_tunnel(context, infos) {
      context.commit('DELETE_TUNNEL', infos);
    },
  },
  getters: {
    tunnelsErrors: (state) => state.tErrors,
    tunnels: (state) => state.tunnels,
    tSouscriptions: (state) => state.tunnels.souscriptions,
    tSouscriptionsM: (state) => state.tunnels.souscriptionsM,
    tArbitrages: (state) => state.tunnels.arbitrages,
    tVersements: (state) => state.tunnels.versements,
    tRachats: (state) => state.tunnels.rachats,
    tKyc: (state) => state.tunnels.kyc,
    tPropositionA: (state) => state.tunnels.propositionA,
    tPropositionEnCoursDeVie: (state) => state.tunnels.propositionEnCoursDeVie,
    tValidationA: (state) => state.tunnels.validationA,
    autresTunnels: (state) => state.tunnels.autresTunnels,
    tunnelsGHGHClos: (state) => state.tunnels.tunnelsGHGHClos,
    nbTunnelsNonFinalises: (state) => parseInt((state.tunnels.souscriptions.length + state.tunnels.souscriptionsM.length + state.tunnels.arbitrages.length + state.tunnels.versements.length + state.tunnels.rachats.length + state.tunnels.kyc.length + state.tunnels.propositionA.length + state.tunnels.validationA.length + state.tunnels.autresTunnels.length + state.tunnels.propositionEnCoursDeVie.length), 10),
    nbTunnelsClientNonFinalises: (state) => parseInt((state.tunnels.souscriptions.length + state.tunnels.souscriptionsM.length + state.tunnels.arbitrages.length + state.tunnels.versements.length + state.tunnels.rachats.length + state.tunnels.kyc.length + state.tunnels.propositionA.length + state.tunnels.autresTunnels.length), 10),
    nbTunnelsSouscNonFinalises: (state) => parseInt((state.tunnels.souscriptions.length + state.tunnels.souscriptionsM.length), 10),
  },
};

export default tunnels;
