import axios from 'axios';

const drupalAutHTTP = axios.create({
  baseURL: process.env.VUE_APP_DRUPAL_BASE_URL,
  withCredentials: true,
});

const drupalHTTP = axios.create({
  baseURL: process.env.VUE_APP_DRUPAL_END_POINT,
  withCredentials: true,
});

const sfAutHTTP = axios.create({
  baseURL: process.env.VUE_APP_SF_END_POINT,
});

const sfNativeWSHTTP = axios.create({
  baseURL: `${process.env.VUE_APP_SF_END_POINT}/${process.env.VUE_APP_SF_WS_URI}`,
});

const sfCustomWSHTTP = axios.create({
  baseURL: `${process.env.VUE_APP_SF_END_POINT}/${process.env.VUE_APP_SF_CUSTOM_WS_URI}`,
});

const mulesoftHTTP = axios.create({
  baseURL: process.env.VUE_APP_MULESOFT_END_POINT,
});

export {
  drupalAutHTTP,
  drupalHTTP,
  sfAutHTTP,
  sfNativeWSHTTP,
  sfCustomWSHTTP,
  mulesoftHTTP
};
