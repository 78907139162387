import {
  sfCustomWSHTTP,
} from '@/axios';
import {
  opInitialState,
} from '@/store/modules/functions-utils';
import {
  getOperationValideesChezHedios,
} from '@/utils/gammesh-utils';

const operations = {
  state: () => (opInitialState()),
  mutations: {
    ADD_LOAD_ERROR(state, error) {
      state.operationsErrors.push(error);
    },
    INIT_OPERATIONS(state) {
      state.operations = {
        enCoursDeValid: [],
        valideesChezHedios: [],
        aValider: [],
        propositionMGHaValider: [],
      };
      state.operationsErrors = [];
    },
    ADD_OPERATIONS_EN_COURS_DE_VALIDATION(state, payload) {
      state.operations.enCoursDeValid.push(payload);
    },
    ADD_OPERATIONS_VALIDEES_CHEZ_HEDIOS(state, payload) {
      state.operations.valideesChezHedios.push(payload);
    },
    ADD_OPERATIONS_PROPOSITION_MGH_A_VALIDER(state, payload) {
      state.operations.propositionMGHaValider.push(payload);
    },
    ADD_OPERATIONS_A_VALIDER(state, payload) {
      state.operations.aValider.push(payload);
    },
    DELETE_OPERATIONS_A_VALIDER(state, payload) {
      state.operations[payload.categ].splice(payload.index, 1);
    },
  },
  actions: {
    load_operations(context, args) {
      context.commit('INIT_OPERATIONS');

      sfCustomWSHTTP.get(`${args.idFicheContact}/operation/?etat=1,2,3,4,5,6`)
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((op) => {
              if (op.typeActe === 'proposition_mgh' && (op.etatSouscription === '1')) {
                context.commit('ADD_OPERATIONS_PROPOSITION_MGH_A_VALIDER', op);
              } else if (op.typeActe === 'validation_arbitrage') {
                context.commit('ADD_OPERATIONS_A_VALIDER', op);
              } else if (op.etatSouscription === '6') {
                context.commit('ADD_OPERATIONS_VALIDEES_CHEZ_HEDIOS', op);
              } else {
                context.commit('ADD_OPERATIONS_EN_COURS_DE_VALIDATION', op);
              }
            });
          } else {
            context.commit('ADD_LOAD_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_LOAD_ERROR', error);
        });
    },
    delete_operation_a_valider(context, payload) {
      context.commit('DELETE_OPERATIONS_A_VALIDER', payload);
    },
  },
  getters: {
    operations: (state) => state.operations,
    OpEnCoursDeValid: (state) => state.operations.enCoursDeValid,
    OpValideesChezHedios: (state) => state.operations.valideesChezHedios,
    opAValider: (state) => state.operations.aValider,
    opMGHAValider: (state) => state.operations.propositionMGHaValider,
    nbOperations: (state) => (state.operations.propositionMGHaValider.length + state.operations.aValider.length + state.operations.enCoursDeValid.length),
    operationsErrors: (state) => state.operationsErrors,
  },
};

export default operations;
