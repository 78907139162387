import lodash from 'lodash';
import {
  mulesoftHTTP
} from '@/axios';
import {
  mvtInitialState,
} from '@/store/modules/functions-utils';

const mouvements = {
  state: () => (mvtInitialState()),
  mutations: {
    INIT_MOUVEMENTS(state) {
      state.mvtErrors = [];
      state.mouvements = [];
      state.totalMvt = 0;
    },
    INIT_MOUVEMENTS_DU_MOIS(state) {
      state.mvtErrors = [];
      state.mouvementsDuMois = [];
    },
    ADD_MOUVEMENTS_ERROR(state, error) {
      state.mvtErrors.push(error);
    },
    LOAD_MOUVEMENTS(state, payload) {
      state.totalMvt = payload.totalMvts;
      state.mouvements = payload.mvts;
    },
    LOAD_MOUVEMENTS_DU_MOIS(state, payload) {
      state.mouvementsDuMois = payload;
    },
  },
  actions: {
    load_mouvements(context, payload) {
      context.commit('INIT_MOUVEMENTS');

      mulesoftHTTP.get(`mouvements/${payload.refCtt}?offset=${payload.offset}&limit=${payload.limit}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('LOAD_MOUVEMENTS', response.data);
          }
        })
        .catch((error) => {
          context.commit('ADD_MOUVEMENTS_ERROR', error);
        });
    },
    load_mouvements_du_mois(context, payload) {
      context.commit('INIT_MOUVEMENTS_DU_MOIS');

      mulesoftHTTP.get(`mouvements-du-mois/${payload.refCtt}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('LOAD_MOUVEMENTS_DU_MOIS', response.data);
          }
        })
        .catch((error) => {
          context.commit('ADD_MOUVEMENTS_ERROR', error);
        });
    },
  },
  getters: {
    mvtErrors: (state) => state.mvtErrors,
    totalMvt: (state) => state.totalMvt,
    mouvements: (state) => state.mouvements,
    mvtsDuMois: (state) => state.mouvementsDuMois,
    othersMvts: (state) => lodash.filter(state.mouvements, (mvt) => !mvt.isMvtDuMois),
  },
};

export default mouvements;
