import VueJwtDecode from 'vue-jwt-decode';
import { EncryptStorage } from 'encrypt-storage';

import {
  sfAutHTTP,
} from '@/axios/index';

const encryptStorage = new EncryptStorage(process.env.VUE_APP_ENCRYPT_SECRET_KEY);

const drupalStorage = 'hediosDrupal';
const sfStorage = 'hediossf';
const drupalErrorStorage = 'drupalError';
const sfErrorStorage = 'sfError';

function setHediosLocalStorage(jwtToken) {
  encryptStorage.setItem(drupalStorage, jwtToken);
}

function getHediosLocalStorage() {
  return encryptStorage.getItem(drupalStorage);
}

function removeHediosLocalStorage() {
  encryptStorage.removeItem(drupalStorage);
}

function setSFLocalStorage(token) {
  encryptStorage.setItem(sfStorage, token);
}

function getSFLocalStorage() {
  return encryptStorage.getItem(sfStorage);
}

function removeSFLocalStorage() {
  encryptStorage.removeItem(sfStorage);
}

function logDrupalErrorInStorage(error) {
  localStorage.setItem(drupalErrorStorage, error);
}

function removeDrupalErrorInStorage() {
  localStorage.removeItem(drupalErrorStorage);
}

function logSFErrorInStorage(error) {
  localStorage.setItem(sfErrorStorage, error);
}

function removeSFErrorInStorage() {
  localStorage.removeItem(sfErrorStorage);
}

function removeAllLocalStorage() {
  // localStorage.removeItem(drupalStorage);
  // localStorage.removeItem(sfStorage);
  localStorage.removeItem(drupalErrorStorage);
  localStorage.removeItem(sfErrorStorage);
}

function isDrupalSessionActive() {
  const storage = getHediosLocalStorage();
  if (!storage) {
    return false;
  }

  const decodeJwt = VueJwtDecode.decode(storage.drupal_jwt);
  return (decodeJwt.exp >= Math.floor(Date.now() / 1000));
}

function handleDrupalWS(store) {
  store.dispatch('load_gammes_h_commerce_en_cours');
  store.dispatch('contrat_autres_actes_de_gestion');
}

function handleRealTimeSalesforceWS(store, payload) {
  store.dispatch('load_client_tunnels', payload);
  // store.dispatch('load_tunnels_proposition_gh_gh_clos', payload);
  store.dispatch('load_operations', payload);
}

function handleAllSalesforceWS(store) {
  const payload = {
    sfAccessToken: store.getters.accessToken,
    sfTokenType: store.getters.tokenType,
    idFicheContact: store.getters.idFicheContact,
    codeParrain: store.getters.codeParrain,
  };
  store.dispatch('get_sf_user', payload);
  store.dispatch('get_gh_encours_user', payload);
  store.dispatch('get_gh_clos_user', payload);
  store.dispatch('get_gh_en_cours_de_remb_user', payload);
  store.dispatch('load_client_tunnels', payload);
  store.dispatch('load_client_opportunities', payload);
  store.dispatch('load_client_all_opp_transfert_per', payload);
  store.dispatch('load_operations', payload);
  // store.dispatch('load_client_relations', payload);
  store.dispatch('load_client_account_relations', payload);
}

function sfOauthToken(assertion) {
  return sfAutHTTP({
    method: 'post',
    url:
      `${process.env.VUE_APP_SF_LOGIN_URI}?grant_type=${process.env.VUE_APP_SF_GRANT_TYPE}&assertion=${assertion}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      client_id: process.env.VUE_APP_MULESOFT_CLIENT_ID,
      client_secret: process.env.VUE_APP_MULESOFT_CLIENT_SECRET,
    },
  });
}

function drupalInjectRequestHeader(request, store) {
  //console.log(store, 'store');
  //request.headers['Authorization'] = store.getters.sfAuthorization;
  //request.headers['code-parrain'] = store.getters.codeParrain;
  //request.headers['x-csrf-token'] = store.getters.servicesToken;
  //request.headers['x-sessid'] = store.getters.sessid;
  //request.headers['x-id-fc'] = store.getters.idFicheContact;
  //request.headers['xsrfCookieName'] = store.getters.sessName;
  //request.headers['Cookie'] = `${store.getters.sessName}=${store.getters.sessid}; CookieConsentPolicy=0:1`;
  //request.headers['client_id'] = process.env.VUE_APP_MULESOFT_CLIENT_ID;
  //request.headers['client_secret'] = process.env.VUE_APP_MULESOFT_CLIENT_SECRET;

  /*if(process.env.NODE_ENV == 'development') {
    request.headers['x-dev-key'] = process.env.VUE_APP_MULE_API_DEV_KEY;
  }*/

  return request;
}

function sfInjectRequestHeader(request, store) {
  //const urlSplit = request.baseURL.split('/');

  //if((process.env.NODE_ENV != 'development') || (urlSplit[4] === 'services')) {
  request.headers['Authorization'] = store.getters.sfAuthorization;
  request.headers['code-parrain'] = store.getters.codeParrain;
  request.headers['x-csrf-token'] = store.getters.servicesToken;
  request.headers['x-sessid'] = store.getters.sessid;
  request.headers['x-id-fc'] = store.getters.idFicheContact;
  request.headers['client_id'] = process.env.VUE_APP_MULESOFT_CLIENT_ID;
  request.headers['client_secret'] = process.env.VUE_APP_MULESOFT_CLIENT_SECRET;

  if(process.env.NODE_ENV == 'development') {
    request.headers['x-dev-key'] = process.env.VUE_APP_MULE_API_DEV_KEY;
  }

  return request;
}


function drupalInitialState() {
  return {
    user: false,
    isDrupalAuth: false,
    token: false,
    sfJwt: false,
  };
}

function sfInitialState() {
  return {
    isSFAuth: false,
    accessToken: null,
    instanceUrl: null,
    id: null,
    tokenType: null,
    scope: null,
    sfUser: null,
    sfErrors: [],
    groupedGH: [],
    groupedGHClos: [],
    groupedGHEnCoursDeRemb: [],
  };
}

function opportunityInitialState() {
  return {
    opportunities: {
      collecte: [],
      general: [],
      walletShare: [],
      reSouscription: [],
      retirementPlanning: [],
      souscription: [],
      transfertPER: [],
      propositionArbitrage: [],
      propositionArbitrageGHGH: [],
      allOppTransPer: [],
    },
    opportunityErrors: [],
  };
}

function tunnelsInitialState() {
  return {
    tunnels: {
      souscriptions: [],
      souscriptionsM: [],
      arbitrages: [],
      versements: [],
      rachats: [],
      kyc: [],
      propositionA: [],
      propositionEnCoursDeVie: [],
      validationA: [],
      autresTunnels: [],
      tunnelsGHGHClos: [],
    },
    tErrors: [],
  };
}

function opInitialState() {
  return {
    operations: {
      enCoursDeValid: [],
      OpValideesChezHedios: [],
      aValider: [],
      propositionMGHaValider: [],
    },
    operationsErrors: [],
  };
}

function gainsInitialState() {
  return {
    prochaingain: {},
    gainsgammesh: [],
    gainsErrors: [],
  };
}

function arbitrageInitialState() {
  return {
    arbitrages: {},
    arbitragesErrors: [],
  };
}

function vArbitrageInitialState() {
  return {
    validationarbitrage: [],
    vaErrors: [],
  };
}

function mghInitialState() {
  return {
    mandats: {},
    mandatErrors: [],
  };
}

function documentInitialState() {
  return {
    dRefCtt: [],
    documents: [],
    actesDeGestion: [],
    dErrors: [],
  };
}

function mvtInitialState() {
  return {
    totalMvt: 0,
    mouvements: [],
    mouvementsDuMois: [],
    mvtErrors: [],
  };
}

function ghcInitialState() {
  return {
    supports: [],
    mentions: '',
    supportsErrors: [],
  };
}

function relationsInitialState() {
  return {
    accountRelations: [],
    relations: [],
    relationsErrors: [],
    accountRelationsErrors: [],
  };
}

export {
  drupalInitialState,
  sfInitialState,
  tunnelsInitialState,
  opportunityInitialState,
  opInitialState,
  gainsInitialState,
  arbitrageInitialState,
  vArbitrageInitialState,
  mghInitialState,
  documentInitialState,
  mvtInitialState,
  ghcInitialState,
  relationsInitialState,
  sfOauthToken,
  isDrupalSessionActive,
  setHediosLocalStorage,
  getHediosLocalStorage,
  removeHediosLocalStorage,
  logDrupalErrorInStorage,
  removeDrupalErrorInStorage,
  setSFLocalStorage,
  getSFLocalStorage,
  removeSFLocalStorage,
  logSFErrorInStorage,
  removeSFErrorInStorage,
  handleDrupalWS,
  handleRealTimeSalesforceWS,
  handleAllSalesforceWS,
  removeAllLocalStorage,
  sfInjectRequestHeader,
  drupalInjectRequestHeader,
};
