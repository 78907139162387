const footerFirstMenuItems = [
  {
    title: 'Concepteur de produits structurés - Hedios Gammes H',
    slot: 'Notre Métier',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/notre-metier`,
  },
  {
    title: 'Performances produits structurés - Hedios Gammes H',
    slot: 'Nos performances',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/performances-gammes-h`,
  },
  {
    title: 'Concepteur de produits structurés - Hedios Gammes H',
    slot: 'À L\'affiche',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/offres-en-cours-gammes-h`,
  },
  {
    title: 'Risques - Hedios Gammes H',
    slot: 'Risques',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/guide-pratique/avertissements`,
  },
  {
    title: '',
    slot: 'Frais',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/guide-pratique/frais`,
  },
  {
    title: 'Mieux comprendre les produits structurés - Hedios Gammes H',
    slot: 'Mieux comprendre',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/mieux-comprendre`,
  },
  {
    title: 'Proposez les Gammes H à votre propre clientèle',
    slot: 'Espace professionnel',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/contact-professionnel`,
  },
];

export default footerFirstMenuItems;
