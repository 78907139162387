import { createRouter, createWebHistory } from 'vue-router';

// import Home from '../views/Home.vue';

const routes = [
  {
    path: '/switch/:origin',
    name: 'Switch',
    component: () => import(/* webpackChunkName: "SwitchUser" */ '../views/SwitchUser.vue'),
    meta: {
      title: 'Mon compte - Connexion en tant que tel',
      requiresAuth: true,
      containerClass: 'connexion-en-tant-que-c',
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Default" */ '../views/Default.vue'),
    meta: {
      title: 'Mon compte - Accueil',
      requiresAuth: true,
      containerClass: 'accueil-c',
    },
  },
  {
    path: '/mouvements-webview/:refctt', // mouvements-webview/5E 266642446
    name: 'mvts-webview',
    component: () => import( /* webpackChunkName: "CadreInvestissement" */
      '../views/MouvementsWebview.vue'),
    props: (route) => ({
      tab: route.query.q,
    }),
    meta: {
      title: 'Mon compte - Mouvements',
      requiresAuth: true,
      containerClass: 'mon-cadre-d-investissement-c',
      type: 'webview'
    },
  },
  {
    path: '',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: 'Mon compte - Accueil',
      requiresAuth: true,
      containerClass: 'accueil-c',
    },
  },
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: 'Mon compte - Accueil',
      requiresAuth: true,
      containerClass: 'accueil-c',
    },
  },
  {
    path: '/mon-portefeuille-gammes-h',
    name: 'portefeuille',
    component: () => import(/* webpackChunkName: "Portefeuille" */ '../views/Portefeuille.vue'),
    meta: {
      title: 'Mon compte - Mon Portefeuille',
      requiresAuth: true,
      containerClass: 'mon-portefeuille-gammes-h-c',
    },
  },
  {
    path: '/mon-mandat-gammes-h',
    name: 'mandatgammesh',
    component: () => import(/* webpackChunkName: "MandatGammesH" */ '../views/MandatGammesH.vue'),
    props: (route) => ({
      tab: route.query.q,
    }),
    meta: {
      title: 'Mon compte - Mandat Gammes H',
      requiresAuth: true,
      containerClass: 'mon-mandat-gammes-h-c',
    },
  },
  {
    path: '/mon-cadre-d-investissement',
    name: 'cadreInvest',
    component: () => import(/* webpackChunkName: "CadreInvestissement" */ '../views/CadreInvestissement.vue'),
    props: (route) => ({
      tab: route.query.q,
    }),
    meta: {
      title: 'Mon compte - Cadre d\'investissement',
      requiresAuth: true,
      containerClass: 'mon-cadre-d-investissement-c',
    },
  },
  {
    path: '/mes-donnees-perso',
    name: 'donneesperso',
    component: () => import(/* webpackChunkName: "DonneesPerso" */ '../views/DonneesPerso.vue'),
    meta: {
      title: 'Mon compte - Mes données personnelles',
      requiresAuth: true,
      containerClass: 'mes-donnees-perso-c',
    },
  },
  {
    path: '/sfauth/callback',
    name: 'Ccallback',
    component: () => import(/* webpackChunkName: "Callback" */ '../views/CallBack.vue'),
    meta: {
      title: 'Mon compte - Callnack',
      requiresAuth: true,
      containerClass: 'callback-c',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
    meta: {
      title: 'Mon compte - logout',
      requiresAuth: true,
      containerClass: 'logout-c',
    },
  },
  {
    path: '/errors',
    name: 'errors',
    component: () => import(/* webpackChunkName: "Errors" */ '../views/Errors.vue'),
    meta: {
      title: 'Mon compte - Oups',
      requiresAuth: true,
      containerClass: 'error-c',
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/errors',
  },
];

const router = createRouter({
  base: '/',
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = {
      left: 0,
      top: 0,
    };

    if (to.hash) {
      position = {
        el: to.hash,
        behavior: 'smooth',
        top: 120,
      };
    } else if (savedPosition) {
      position = savedPosition;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position);
      }, 2000);
    });
  },
});

export default router;
