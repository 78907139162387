const footerSecondMenuItems = [
  {
    title: 'Qui sommes nous? - Hedios Gammes H',
    slot: 'Qui sommes-nous ?',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/qui-sommes-nous`,
  },
  {
    title: 'Documentation - Hedios Gammes H',
    slot: 'Documentation',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/documentation`,
  },
  {
    title: 'Podcasts',
    slot: 'Podcasts',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/podcast-hedios`,
  },
  {
    title: 'Les Gammes H en vidéo - Hedios Gammes H',
    slot: 'Vidéos',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/gammes-h-en-video`,
  },
  {
    title: 'Découvrez les films Hedios',
    slot: 'Films',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/film-hedios`,
  },
  {
    title: 'La presse en parle - Hedios Gammes H',
    slot: 'Revue de presse',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/revue-presse`,
  },
  {
    title: 'Mentions légales - Hedios Gammes H',
    slot: 'Mentions Légales',
    to: `${process.env.VUE_APP_DRUPAL_BASE_URL}/mentions-legales`,
  },
];

export default footerSecondMenuItems;
