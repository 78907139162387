<template>
  <div
    id="nav"
    class="blk-menu-ec"
  >
    <div
      class="container-fluid"
      :class="{ tabletLayout: isTabOrMob }"
    >
      <div
        v-if="isTabOrMob"
        class="js-btn-mob"
        :class="{ isTabOrMob: isTabOrMob }"
      >
        <button
          class="js-btn-menu"
          :class="{ opened: showNav }"
          @click="displayMenuMob('menu')"
        >
          <span />
        </button>
        <button
          class="js-btn-action-rap"
          :class="{ opened: showFastNav }"
          @click="displayMenuMob('fastMenu')"
        >
          <span
            class="textRap"
          >
            {{ $t("menu.fastTitle") }}
          </span>
          <span
            class="textRapAfter"
          />
        </button>
      </div>
      <div
        v-if="!isTabOrMob && isBusinessAccount"
        class="blk-name"
      >
        {{ $t("salutation") }},<br>{{ getUsername }}
      </div>
      <div
        v-else-if="!isTabOrMob"
        class="blk-name"
      >
        {{ $t("salutation") }},<br>{{ sfUserName }}
      </div>
      <nav
        v-show="showNav"
        :class="{ isMobile: isTabOrMob }"
      >
        <ul v-if="!isTUnderscore(drupalLogin)">
          <router-link
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'Home' }"
            custom
          >
            <li :class="{ active: isActive }">
              <a
                :href="href"
                @click="hediosNavigate(navigate)"
                v-html="$t('menu.item1')"
              />
            </li>
          </router-link>
          <router-link
            v-if="haveGammesH"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'portefeuille' }"
            custom
          >
            <li :class="{ active: isActive }">
              <a
                :href="href"
                @click="hediosNavigate(navigate)"
                v-html="$t('menu.item2')"
              />
            </li>
          </router-link>
          <router-link
            v-if="haveContratVie || haveRelations"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'cadreInvest' }"
            custom
          >
            <li :class="{ active: isActive }">
              <a
                :href="href"
                @click="hediosNavigate(navigate)"
                v-html="$t('menu.item4')"
              />
            </li>
          </router-link>
          <router-link
            v-if="!isBusinessAccount"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'donneesperso' }"
            custom
          >
            <li :class="{ active: isActive }">
              <a
                :href="href"
                @click="hediosNavigate(navigate)"
                v-html="$t('menu.item5')"
              />
            </li>
          </router-link>
        </ul>
      </nav>
      <menu-rapide
        v-show="showFastNav"
        :items="fastMenuItems"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isMobile, isTablet, isBrowser } from 'mobile-device-detect';
import fastMenuItems from '@/data/fast-menu';
import FastMenu from '@/components/utils/FastMenu.vue';
import { isTUnderscore } from '@/utils/index';

export default {
  name: 'HediosMenu',
  components: {
    'menu-rapide': FastMenu,
  },
  data() {
    return {
      fastMenuItems,
      isMobile,
      isTablet,
      isBrowser,
      isTUnderscore,
      showMenuMob: false,
      showFastMenuMob: false,
    };
  },
  computed: {
    ...mapGetters([
      'sfUser',
      'sfUserName',
      'contrats',
      'gammesh',
      'gainsGammesH',
      'gammeshEnCoursDeRemb',
      'opAValider',
      'nbTunnelsClientNonFinalises',
      'OpEnCoursDeValid',
      'nombreMandat',
      'drupalLogin',
      'OpValideesChezHedios',
      'relations',
      'accountRelations',
    ]),
    isClientMGH() {
      return (
        ![undefined, null, false, ''].includes(this.sfUser)
        && this.sfUser.segment === 'Client MGH'
      );
    },
    haveContratVie() {
      return (
        ![undefined, null, false, ''].includes(this.contrats) && this.contrats.length > 0
      );
    },
    haveRelations() {
      return (
        (![undefined, null, false, ''].includes(this.relations) && this.relations.length > 0)
        ||
        (![undefined, null, false, ''].includes(this.accountRelations) && this.accountRelations.length > 0)
      );
    },
    haveGammesH() {
      return (
        (![undefined, null, false, ''].includes(this.gammesh)
          && this.gammesh.length > 0)
        || this.OpEnCoursDeValid.length > 0
        || this.OpValideesChezHedios.length > 0
        || this.nbTunnelsClientNonFinalises > 0
        || (![undefined, null, false, ''].includes(this.gainsGammesH)
          && this.gainsGammesH > 0)
        || (![undefined, null, false, ''].includes(this.gammeshEnCoursDeRemb)
          && this.gammeshEnCoursDeRemb.length > 0)
      );
    },
    isTabOrMob() {
      return this.isTablet || this.isMobile;
    },
    showNav() {
      if (this.isTabOrMob) {
        return this.showMenuMob;
      }
      return true;
    },
    showFastNav() {
      return (this.isTabOrMob && this.showFastMenuMob);
    },
    isBusinessAccount() {
      return (this.sfUser) ? this.sfUser.isBusinessAccount : false;
    },
    getUsername() {
      return this.sfUser.name;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    // Add Prendre rendez-vous on Action Rapides
    /*
    if (this.sfUser && this.sfUser.calendlyUrl !== '') {
      const calendlyExists = this.fastMenuItems.findIndex((item) => item.to === 'calendly');

      if (calendlyExists < 0) {
        this.fastMenuItems.splice(-1, 0, {
            slot: 'Prendre rendez-vous',
            to: 'calendly',
          },
        );
      }
    } */
  },
  methods: {
    displayMenuMob(item) {
      if (this.isTabOrMob) {
        if (item === 'menu') {
          this.showMenuMob = !this.showMenuMob;
          this.showFastMenuMob = false;
        } else if (item === 'fastMenu') {
          this.showMenuMob = false;
          this.showFastMenuMob = !this.showFastMenuMob;
        }
      }
    },
    hediosNavigate(navigate) {
      if (this.isTabOrMob) {
        this.displayMenuMob('menu');
      }

      navigate();
    },
    handleScroll() {
      if (window.scrollY > 0) {
        if (this.isTabOrMob) {
          this.showFastMenuMob = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* Menu compte */
#mast {
  .blk {
    &-menu-ec {
      background: $accent-4;
      @include transition(0.15s);
      min-height: 50px;
      display: flex;
      align-items: center;
      font-family: $font-light;
      letter-spacing: 0.075em;
      > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .blk-name {
        width: 20%;
        color: $white;
      }
      nav {
        width: 80%;
        ul {
          display: flex;
          width: 100%;
        }
        li {
          display: inline-block;
          color: $white;
          text-transform: uppercase;
          line-height: normal;
          text-align: center;
        }
        a {
          color: $accent-24;
          padding: 10px;
          display: inline-block;
          font-family: $font-light;
        }
        .active {
          position: relative;
          a {
            color: $white;
          }
          &:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 18px solid $accent-4;
            position: absolute;
            bottom: -18px;
            left: calc(50% - 12px);
          }
        }
      }
    }
  }
  .js-btn-action-rap {
    float: right;
    display: flex;
    align-items: center;
    padding: 15px 35px;
    background: $accent-2;
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 1.25em;
    font-family: $font-reg;
  }
}
</style>
