import { createStore } from 'vuex';
import {
  sfInitialState,
  drupalInitialState,
  tunnelsInitialState,
  opportunityInitialState,
  opInitialState,
  gainsInitialState,
  arbitrageInitialState,
  vArbitrageInitialState,
  mghInitialState,
  documentInitialState,
  mvtInitialState,
  ghcInitialState,
  relationsInitialState,
} from '@/store/modules/functions-utils';

import salesforce from './modules/salesforce';
import drupalAuth from './modules/drupalAuth';
import tunnels from './modules/tunnels';
import opportunities from './modules/opportunity';
import operations from './modules/operations';
import gammeshCommerce from './modules/gammeshCommerce';
import documentsStore from './modules/documentsStore';
import mouvements from './modules/mouvements';
import relations from './modules/relations';

// initial state
const initialState = {
  salesforce: sfInitialState(),
  drupalAuth: drupalInitialState(),
  tunnels: tunnelsInitialState(),
  opportunities: opportunityInitialState(),
  operations: opInitialState(),
  gains: gainsInitialState(),
  gammeshCommerce: ghcInitialState(),
  arbitrages: arbitrageInitialState(),
  validationArbitrage: vArbitrageInitialState(),
  mandatGammesH: mghInitialState(),
  documentsStore: documentInitialState(),
  mouvements: mvtInitialState(),
  relations: relationsInitialState(),
};

export default createStore({
  plugins: [
  ],
  state: {
    pageSpinner: true,
    siteSpinner: true,
    countries: [],
    cities: [],
  },
  actions: {
    mon_compte_reset(context) {
      Object.keys(context.state).forEach((key) => {
        Object.assign(context.state[key], initialState[key]);
      });
    },
  },
  modules: {
    drupalAuth,
    salesforce,
    tunnels,
    opportunities,
    operations,
    gammeshCommerce,
    documentsStore,
    mouvements,
    relations,
  },
});
