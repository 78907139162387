import {
  sfAutHTTP,
  sfCustomWSHTTP,
} from '@/axios/index';

import {
  getAllGammesH,
  getGroupedGammesH,
  getAllGammeshClotures,
  getGroupedGammeshClotures,
  // getGainsGammesH,
  getGammesHAvecGain,
  getAllGammesHEnCoursDeRemb,
  getGroupedGHEnCoursDeRemb,
  getTotalGammesH,
  getTotalGammesHEnCoursDeRemb,
  getGammesHProchainGain,
  haveMandatGammesH,
  getMontantTotalSousMandat,
  getMontantTotalFondsDisponibles,
  getNombreDeMandatGammesH,
  getGammesHAArbitrer,
  getAllcontratsAvecMandat,
  getContratsEnCours,
  getContratsClos,
  getContratsLiquidites,
  getTotalGammesHMontantInvesti,
  getTotalProchainsGains,
  getAllowEditInfoPers,
  havePERNotif1,
  havePERNotif2,
  havePERNotif3,
  havePERNotif4,
} from '@/utils/gammesh-utils';

import {
  sfInitialState,
  getSFLocalStorage,
  setSFLocalStorage,
  removeSFLocalStorage,
  logSFErrorInStorage,
  sfOauthToken,
} from '@/store/modules/functions-utils';

import {
  isValidPhoneNumber,
  isValidEmail,
} from '@/utils/index';

const salesforce = {
  state: () => (sfInitialState()),
  mutations: {
    LOGIN_SF(state, payload) {
      state.accessToken = payload.access_token;
      state.instanceUrl = payload.instance_url;
      state.id = payload.id;
      state.tokenType = payload.token_type;
      state.scope = payload.scope;
      state.isSFAuth = (state.accessToken !== null);
    },
    LOG_OUT_SF(state) {
      state.isSFAuth = false;
      state.accessToken = null;
      state.instanceUrl = null;
      state.id = null;
      state.tokenType = null;
      state.scope = null;
      state.sfUser = null;
      state.errors = [];
      state.groupedGH = [];
      state.groupedGHClos = [];
    },
    INIT_SF_ERROR(state) {
      state.sfErrors = [];
    },
    ADD_SF_ERROR(state, error) {
      state.sfErrors.push(error);
    },
    SET_SF_USER(state, payload) {
      payload.telephones.forEach((telephone, key) => {
        payload.telephones[key].isValid = isValidPhoneNumber(telephone.tel);
      });
      payload.emails.forEach((mail, key) => {
        payload.emails[key].isValid = isValidEmail(mail.email);
      });
      state.sfUser = payload;
    },
    SET_SF_GH(state, payload) {
      state.groupedGH = payload;
    },
    SET_SF_GH_CLOS(state, payload) {
      state.groupedGHClos = payload;
    },
    SET_SF_GH_EN_COURS_DE_REMB(state, payload) {
      state.groupedGHEnCoursDeRemb = payload;
    },
  },
  actions: {
    login_sf(context, salesforceJWT) {
      sfOauthToken(salesforceJWT)
        .then((response) => {
          if (response.status === 200) {
            context.commit('LOGIN_SF', response.data);
            setSFLocalStorage(response.data);
          } else {
            logSFErrorInStorage(response.data);
          }
        })
        .catch((error) => {
          logSFErrorInStorage(error);
        });
    },
    logout_sf(context) {
      const sfStorage = getSFLocalStorage();
      const token = (sfStorage.access_token) ? sfStorage.access_token : context.state.accessToken;

      if (token) {
        sfAutHTTP.post(process.env.VUE_APP_SF_LOGOUT_URI, { token })
          .then((response) => {
            if (response.status === 200) {
              context.commit('LOG_OUT_SF');
              removeSFLocalStorage(response.data);
            } else {
              logSFErrorInStorage(response.data);
            }
          })
          .catch((error) => {
            logSFErrorInStorage(error);
          });
      }
    },
    get_sf_user(context, args) {
      context.commit('INIT_SF_ERROR');

      sfCustomWSHTTP.get(`${args.idFicheContact}/donnees-perso/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_SF_USER', response.data[0]);
          } else {
            context.commit('ADD_SF_ERROR', response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('ADD_SF_ERROR', error.response.data);
          } else {
            context.commit('ADD_SF_ERROR', error);
          }
        });
    },
    get_gh_encours_user(context, args) {
      context.commit('INIT_SF_ERROR');

      sfCustomWSHTTP.get(`${args.idFicheContact}/gammesh/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_SF_GH', response.data);
          } else {
            context.commit('ADD_SF_ERROR', response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('ADD_SF_ERROR', error.response.data);
          } else {
            context.commit('ADD_SF_ERROR', error);
          }
        });
    },
    get_gh_clos_user(context, args) {
      context.commit('INIT_SF_ERROR');

      sfCustomWSHTTP.get(`${args.idFicheContact}/gammesh/?type=closed`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_SF_GH_CLOS', response.data);
          } else {
            context.commit('ADD_SF_ERROR', response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('ADD_SF_ERROR', error.response.data);
          } else {
            context.commit('ADD_SF_ERROR', error);
          }
        });
    },
    get_gh_en_cours_de_remb_user(context, args) {
      context.commit('INIT_SF_ERROR');

      sfCustomWSHTTP.get(`${args.idFicheContact}/gammesh/?type=encoursderemb`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('SET_SF_GH_EN_COURS_DE_REMB', response.data);
          } else {
            context.commit('ADD_SF_ERROR', response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('ADD_SF_ERROR', error.response.data);
          } else {
            context.commit('ADD_SF_ERROR', error);
          }
        });
    },
  },
  getters: {
    sfErrors: (state) => state.sfErrors,
    isSFAuth: (state) => state.isSFAuth,
    accessToken: (state) => state.accessToken,
    instanceUrl: (state) => state.instanceUrl,
    id: (state) => state.id,
    tokenType: (state) => state.tokenType,
    scope: (state) => state.scope,
    sfUser: (state) => state.sfUser,
    sflogindErrors: (state) => state.errors,
    sfAuthorization: (state) => `${state.tokenType} ${state.accessToken}`,
    sfUserName: (state) => ((state.sfUser) ? `${state.sfUser.civilite} ${state.sfUser.name}` : ''),
    contrats: (state) => ((state.sfUser) ? getContratsEnCours(state.sfUser.contratsVie) : []),
    contratClos: (state) => ((state.sfUser) ? getContratsClos(state.sfUser.contratsVie) : []),
    // groupedGH: (state) => ((state.sfUser) ? getGroupedGammesH(state.sfUser.contratsVie) : []),
    groupedGH: (state) => ((state.groupedGH) ? state.groupedGH : []),
    gammesh: (state) => ((state.sfUser) ? getAllGammesH(state.sfUser.contratsVie) : []),
    gainsGammesH: (state) => ((state.sfUser) ? state.sfUser.totalGainGH : 0),
    totalProchainsGains: (state) => ((state.sfUser) ? getTotalProchainsGains(state.sfUser.contratsVie) : 0),
    totalGammesHMontantInvesti: (state) => ((state.sfUser) ? getTotalGammesHMontantInvesti(state.sfUser.contratsVie) : 0),

    gammesHAvecGains: (state) => ((state.groupedGH || state.groupedGHClos) ? getGammesHAvecGain(
      state.groupedGH, state.groupedGHClos, state.groupedGHEnCoursDeRemb): []),

    // allGammeshClosed: (state) => ((state.sfUser) ? getAllGammeshClotures(state.sfUser.contratsVie) : []),
    allGammeshClosed: (state) => ((state.groupedGHClos) ? state.groupedGHClos : []),
    // gammeshClosed: (state) => ((state.sfUser) ? getGroupedGammeshClotures(state.sfUser.contratsVie) : []),
    gammeshClosed: (state) => ((state.groupedGHClos) ? state.groupedGHClos : []),
    gammeshEnCoursDeRemb: (state) => ((state.sfUser) ? getAllGammesHEnCoursDeRemb(state.sfUser.contratsVie) : []),
    groupedGHEnCoursDeRemb: (state) => ((state.sfUser) ? state.groupedGHEnCoursDeRemb : []),
    //groupedGHEnCoursDeRemb: (state) => ((state.sfUser) ? getGroupedGHEnCoursDeRemb(state.sfUser.contratsVie) : []),
    totalGammesH: (state) => ((state.sfUser) ? getTotalGammesH(state.sfUser.contratsVie) : 0),
    totalGammesHEnCoursDeRemb: (state) => ((state.sfUser) ? getTotalGammesHEnCoursDeRemb(state.sfUser.contratsVie) : 0),
    prochainGainGH: (state) => ((state.sfUser) ? getGammesHProchainGain(state.sfUser.contratsVie) : ''),
    haveMandat: (state) => ((state.sfUser) ? haveMandatGammesH(state.sfUser.contratsVie) : false),
    nombreMandat: (state) => ((state.sfUser) ? getNombreDeMandatGammesH(state.sfUser.contratsVie) : 0),
    montantTotalSousMandat: (state) => ((state.sfUser) ? getMontantTotalSousMandat(state.sfUser.contratsVie) : 0),
    contratsAvecMandat: (state) => ((state.sfUser) ? getAllcontratsAvecMandat(state.sfUser.contratsVie) : []),
    mttTotalFondsDispo: (state) => ((state.sfUser) ? getMontantTotalFondsDisponibles(state.sfUser.contratsVie) : 0),
    gammeshAArbitrer: (state) => ((state.sfUser) ? getGammesHAArbitrer(state.sfUser.contratsVie) : []),
    //liquidite: (state) => ((state.sfUser) ? getContratsLiquidites(state.sfUser.contratsVie) : 0),
    liquidite: (state) => ((state.sfUser) ? state.sfUser.liquidites : 0),
    kycIdentifiantExterne: (state) => ((state.sfUser && state.sfUser.kyc) ? state.sfUser.kyc.identifiantExterne : null),
    allowEditInfoPerso: (state) => ((state.sfUser) ? getAllowEditInfoPers(state.sfUser) : false),
    havePERNotif1: (state) => ((state.sfUser) ? havePERNotif1(state.sfUser) : false),
    havePERNotif2: (state) => ((state.sfUser) ? havePERNotif2(state.sfUser) : false),
    havePERNotif3: (state) => ((state.sfUser) ? havePERNotif3(state.sfUser) : false),
    havePERNotif4: (state) => ((state.sfUser) ? havePERNotif4(state.sfUser) : false),
  },
};

export default salesforce;
