import VueJwtDecode from 'vue-jwt-decode';
import { drupalAutHTTP } from '@/axios/index';
import {
  setHediosLocalStorage,
  removeHediosLocalStorage,
  logDrupalErrorInStorage,
  drupalInitialState,
} from '@/store/modules/functions-utils';

const drupalAuth = {
  state: () => drupalInitialState(),
  mutations: {
    LOGIN_USER(state, payload) {
      state.token = payload.drupal_jwt;
      state.user = VueJwtDecode.decode(payload.drupal_jwt);
      state.isDrupalAuth = ((state.user !== undefined) && (parseInt(state.user.idFicheContact, 10) > 1));
      state.sfJwt = payload.sf_jwt;
    },
  },
  actions: {
    login_drupal(context) {
      drupalAutHTTP.get('hedios/rest/jwt_token')
        .then((response) => {
          if (response.status === 200) {
            context.commit('LOGIN_USER', response.data);
            setHediosLocalStorage(response.data);
          } else {
            context.dispatch('login_error', response.data);
          }
        })
        .catch((error) => {
          context.dispatch('login_error', error);
        });
    },
    login_error(context, error) {
      context.dispatch('mon_compte_reset');
      removeHediosLocalStorage();
      logDrupalErrorInStorage(error);
      let loginUrl = `${process.env.VUE_APP_DRUPAL_BASE_URL}/user`;
      if (window.location.pathname) {
        const uriDest = encodeURIComponent(`${window.location.pathname.substring(1)}${window.location.search}${window.location.hash}`);
        loginUrl = `${loginUrl}/?destination=${uriDest}`;
      }
      window.location = loginUrl;
    },
  },
  getters: {
    drupalUser: (state) => (state.user),
    drupalUID: (state) => (state.user.uid),
    drupalLogin: (state) => (state.user.drupalName),
    userName: (state) => (state.user.name),
    idFicheContact: (state) => (state.user.idFicheContact),
    isDrupalAuth: (state) => (state.isDrupalAuth),
    codeParrain: (state) => parseInt(state.user.codeParrain, 10),
    sessid: (state) => state.user.sessid,
    servicesToken: (state) => state.user.servicesToken,
    sfJwt: (state) => (state.sfJwt),
  },
};

export default drupalAuth;
