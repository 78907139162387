<template>
  <div class="hedios-loading">
    <div
      class="vue-simple-spinner"
      :style="spinner_style"
    />
    <div
      v-if="message.length > 0"
      class="vue-simple-spinner-text"
      :style="text_style"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { isNumber } from '@/utils/index';

export default {
  name: 'HediosSpinner',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    lineSize: {
      type: Number,
      default: 3,
    },
    lineBgColor: {
      type: String,
      default: '#eee',
    },
    lineFgColor: {
      type: String,
      default: '#c6a166', // match .blue color to Material Design's 'Blue 500' color
    },
    speed: {
      type: Number,
      default: 0.8,
    },
    spacing: {
      type: Number,
      default: 4,
    },
    message: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 13,
    },
    textFgColor: {
      type: String,
      default: '#555',
    },
  },
  data() {
    return {
      isNumber,
    };
  },
  computed: {
    size_px() {
      switch (this.size) {
        case 'tiny':
          return 12;
        case 'small':
          return 16;
        case 'medium':
          return 32;
        case 'large':
          return 48;
        case 'big':
          return 64;
        case 'huge':
          return 96;
        case 'massive':
          return 128;
        default:
          return this.isNumber(this.size) ? this.size : 32;
      }
    },
    line_size_px() {
      switch (this.size) {
        case 'tiny':
          return 1;
        case 'small':
          return 2;
        case 'medium':
          return 3;
        case 'large':
          return 3;
        case 'big':
          return 4;
        case 'huge':
          return 4;
        case 'massive':
          return 5;
        default:
          return this.isNumber(this.lineSize) ? this.lineSize : 4;
      }
    },
    text_margin_top() {
      switch (this.size) {
        case 'tiny':
        case 'small':
        case 'medium':
        case 'large':
        case 'big':
        case 'huge':
        case 'massive':
          return Math.min(Math.max(Math.ceil(this.size_px / 8), 3), 12);
        default:
          return this.isNumber(this.spacing) ? this.spacing : 4;
      }
    },
    text_font_size() {
      switch (this.size) {
        case 'tiny':
        case 'small':
        case 'medium':
        case 'large':
        case 'big':
        case 'huge':
        case 'massive':
          return Math.min(Math.max(Math.ceil(this.size_px * 0.4), 11), 32);
        default:
          return this.isNumber(this.fontSize) ? this.fontSize : 13;
      }
    },
    spinner_style() {
      return {
        margin: '0 auto',
        'border-radius': '100%',
        border: `${this.line_size_px}px solid ${this.lineBgColor}`,
        'border-top': `${this.line_size_px}px solid ${this.lineFgColor}`,
        width: `${this.size_px}px`,
        height: `${this.size_px}px`,
        '-webkit-transform': 'translateZ(0)',
        '-ms-transform': 'translateZ(0)',
        transform: 'translateZ(0)',
        '-webkit-animation': `load8 ${this.speed}s infinite linear`,
        animation: `load8 ${this.speed}ss infinite linear`,
        'margin-top': '20px',
      };
    },
    text_style() {
      return {
        'margin-top': `${this.text_margin_top}px`,
        color: this.textFgColor,
        'font-size': `${this.text_font_size}px`,
        'text-align': 'center',
      };
    },
  },
};
</script>

<style>
.hedios-loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px auto;
  display: flex;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
