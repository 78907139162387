<template>
  <div class="col-md-6">
    <div class="blk-brochure">
      <h2 v-html="$t('footer.blk.brochure.title')" />
      <a
        class="bouton bouton-sty_2"
        :href="brochureDocLink"
        target="_blank"
      >{{ $t('footer.blk.brochure.btnDownload') }}</a>
      <a
        class="bouton bouton-sty_2"
        :href="brochureFormLink"
        target="_blank"
      >{{ $t('footer.blk.brochure.btnCourrier') }}</a>
    </div>
  </div>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';

export default {
  name: 'BlkBrochure',
  data() {
    return {
      isMobile,
      isBrowser,
      brochureDocLink: `${process.env.VUE_APP_DRUPAL_BASE_URL}/${process.env.VUE_APP_BROCHURE_DOC_LINK}`,
      brochureFormLink: `${process.env.VUE_APP_DRUPAL_BASE_URL}/${process.env.VUE_APP_BROCHURE_FORM_LINK}`,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
