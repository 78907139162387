<template>
  <div class="col-md-6">
    <div class="blk-contact">
      <h2 v-html="$t('footer.blk.contact.title')" />
      <strong>{{ $t('footer.blk.contact.phone') }}</strong>
      <p v-html="$t('footer.blk.contact.paraph')" />
    </div>
  </div>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';

export default {
  name: 'BlkContact',
  data() {
    return {
      isMobile,
      isBrowser,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
