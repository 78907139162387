<template>
  <div
    class="col-sm-3 wow fadeInUp"
    data-wow-duration="1.75s"
    style="visibility: visible; animation-duration: 1.75s; animation-name: fadeInUp;"
  >
    <div class="footer-contact-menu">
      <h2
        v-if="isBrowser"
        class="hideMob"
      >
        {{ $t('footer.contactus') }}
      </h2>
      <ul
        v-if="isBrowser"
        class="hideMob"
      >
        <li>
          <a
            :href="contactUrl"
            target="_blank"
            :title="$t('footer.contactusTitle')"
          >
            <i class="icon-telephone" />
          </a>
        </li>
        <li>
          <a
            :href="contactUrl"
            target="_blank"
            :title="$t('footer.contactusTitle')"
          >
            <i class="icon-location" />
          </a>
        </li>
        <li>
          <a
            :href="contactUrl"
            target="_blank"
            :title="$t('footer.contactusTitle')"
          >
            <i class="icon-email" />
          </a>
        </li>
      </ul>
      <h2>{{ $t('footer.suiveznous') }}</h2>
      <ul>
        <li
          v-for="(item, index) in items"
          :key="index"
        >
          <a
            :href="item.to"
            target="_blank"
            :title="item.title"
            v-html="item.slot"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';

export default {
  name: 'FooterContact',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
      contactUrl: `${process.env.VUE_APP_DRUPAL_BASE_URL}/contact`,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
