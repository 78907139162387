<template>
  <div class="head-menu">
    <ul class="header-logos">
      <li class="menu-tel">
        <a href="tel:+330153459800">
          <i class="icon-telephone" />
          <span>{{ $t('footer.blk.contact.phone') }}</span>
        </a>
      </li>
      <li>
        <a
          class="js-menu-compte"
          @click.stop="handleSubmenu"
        >
          <i class="icon-ic_person_24px" />
          <span>{{ $t('HeaderNav.moncompte.title') }}</span>
        </a>
      </li>
    </ul>
    <div
      v-show="showSubmenu"
      class="head-submenu"
      :style="subMenuStyle"
    >
      <ul>
        <li>
          <a
            :href="contactUrl"
            target="_blank"
          >
            {{ $t('contact') }}
          </a>
        </li>
        <li>
          <a href="/logout">
            {{ $t('logout') }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HediosNav',
  data() {
    return {
      nombreMsg: 3,
      showSubmenu: false,
      subMenuStyle: { display: 'none' },
    };
  },
  computed: {
    contactUrl() {
      return `${process.env.VUE_APP_DRUPAL_BASE_URL}/contact`;
    },
  },
  methods: {
    handleSubmenu() {
      this.showSubmenu = !this.showSubmenu;
      this.subMenuStyle.display = (this.subMenuStyle.display === 'none') ? 'block' : 'none';
    },
  },
};
</script>

<style lang="scss">
/* Menu compte */
#mast {
  .head-menu {
    float: right;
    li {
      display: inline-block;
      text-align: center;
      padding: 0 15px;
      span {
        display: block;
        width: 100%;
      }
    }
    a {
      color: $accent-2;
    }
    i {
      font-size: 2.35em;
      position: relative;
      &.icon-ic_mail {
        font-size: 2.65em;
      }
      em {
        position: absolute;
        background-color: $notif-1;
        padding: 2px;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 12px;
        font-style: normal;
        @include border-radius(50%);
        top: -5px;
        right: -10px;
        font-family: $font-semi;
      }
    }
    .head-submenu {
      display: block;
      position: absolute;
      @include box-shadow(0px 0px 6px 2px rgba(0,0,0,.1));
      background-color: $white;
      z-index: 1;
      top: 100%;
      right: 15px;
      padding: 8px 0;
      width: 150px;
      li {
        padding: 5px;
        display: inline-block;
        width: 100%;
        a {
          color: $black;
        }
      }
    }
  }
}
</style>
