<template>
  <div>
    <main>
      <div class="blk-main-ec">
        <div class="container-fluid">
          <div class="row">
            <div class="home col-sm-12">
              <div class="blk-notif blk-notif-wide">
                <div class="alerte">
                  <h2>Notification</h2>
                  <div class="msg">
                    <p>Votre navigateur n'est pas à jour pour accéder à votre compte.<br>Nous vous invitons à télécharger la dernière version en cliquant sur "En savoir plus".</p>
                  </div>
                  <a
                    href="https://browser-update.org/update-browser.html"
                    target="_blank"
                    class="bouton bouton-sty_2"
                  >
                    En savoir plus
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'BrowserNotSupported',
};
</script>

<style lang="scss">
.blk {
  &-notif {
    > div {
      &.alerte {
        &:after {
          content: "";
          position: absolute;
          width: 55px;
          height: 41px;
          right: 0;
          top: 0;
          display: inline-block;
          background: url("~@/assets/img/corner-1.png") no-repeat;
          @media screen and (max-width: $breakpoint-m) {
            right: -1px !important;
            top: -1px !important;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        width: 55px;
        height: 41px;
        right: 0;
        top: 0;
        display: inline-block;
        background: url("~@/assets/img/corner-2.png") no-repeat;
      }
    }
    &-wide {
      margin: 0 1.5% 40px 1.5%;
      h2 {
        text-transform: uppercase;
        font-size: 0.75em;
        color: $white;
        letter-spacing: 0.15em;
      }
      h3, strong, span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      h3 {
        font-family: $montserratbold;
        color: $black;
        font-size: 1.1em;
      }
      strong {
        color: $accent-2;
        font-size: 2.1em;
        font-family: $montserratbold;
        font-weight: normal;
        &.big {
          font-size: 3.85em;
          line-height: normal;
        }
      }
      span {
        color: $black;
      }
      .msg {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        color: $white;
        flex-direction: column;
        width: 60%;
        p {
          font-family: $font-semi;
          font-size: 1.65em;
          line-height: normal;
        }
      }
      .alerte {
        background-color: $notif-1;
        position: relative;
        color: $white;
        padding: 30px;
        display: flex;
        align-items: center;
        @media screen and (max-width: $breakpoint-xs) {
          flex-direction: column;
          text-align: center;
          justify-content: center;
          padding: 15px;
          .msg {
            padding: 15px 0;
          }
        }
      }
      @media screen and (max-width: $breakpoint-xs) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
