function getRealDate(d) {
  try {
    if (![undefined, null, false, ''].includes(d)) {
      const date = d.split('-');

      if (date.length === 3) {
        const days = date[2].split('T');
        return new Date(date[0], (date[1] - 1), days[0]);
      }
    }

    return d;
  } catch (error) {
    return d;
  }
}

function dateFormatShort(dateF) {
  if ([undefined, null, false, ''].includes(dateF)) {
    return false;
  }

  const date = getRealDate(dateF).valueOf();
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE).format(date);
}

function dateFormatMedium(dateF) {
  if ([undefined, null, false, ''].includes(dateF)) {
    return false;
  }

  const date = getRealDate(dateF).valueOf();
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, options).format(date);
}

function dateGetYear(dateF) {
  if ([undefined, null, false, ''].includes(dateF)) {
    return false;
  }

  const date = getRealDate(dateF);
  const options = {
    year: 'numeric',
  };
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, options).format(date);
}

function dateFormatLong(dateF) {
  if ([undefined, null, false, ''].includes(dateF)) {
    return false;
  }

  const date = getRealDate(dateF).valueOf();
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, {
    dateStyle: 'full',
  }).format(date);
}

function timestampToLongDate(timeFrom) {
  if (![undefined, null, false, ''].includes(timeFrom)) {
    const date = getRealDate(timeFrom * 1000);
    return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, {
      dateStyle: 'full',
    }).format(date);
  }

  return timeFrom;
}

function isPastDate(dateP) {
  if ([undefined, null, false, ''].includes(dateP)) {
    return false;
  }
  const now = new Date();
  dateP = getRealDate(dateP);

  if (dateP.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
}

function isFurtureDate(dateP) {
  if ([undefined, null, false, ''].includes(dateP)) {
    return false;
  }
  const now = new Date();
  dateP = getRealDate(dateP);

  if (dateP.setHours(0, 0, 0, 0) > now.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
}

function isMajeur(dateP) {
  if ([undefined, null, false, ''].includes(dateP)) {
    return false;
  }

  const nowYear = new Date().getFullYear();
  const birthYear = getRealDate(dateP).getFullYear();

  return ((nowYear - birthYear) >= 18);
}

function dateCurrentMonthLong() {
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, { month: 'long' }).format(new Date());
}

function dateCurrentMonthYear() {
  const date = new Date();
  const options = {
    year: 'numeric',
    month: 'long',
  };
  return new Intl.DateTimeFormat(process.env.VUE_APP_I18N_LOCALE, options).format(date);
}

function getLastYear() {
  return (new Date().getFullYear() - 1);
}

function getDatesDiffInDays(date1, date2) {
  const d1 = getRealDate(date1);
  const d2 = getRealDate(date2);

  if ([undefined, null, false, ''].includes(d1) || [undefined, false, ''].includes(d2)) {
    return false;
  }

  // To calculate the no. of days between two dates
  return ((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
}

function getDatesDiffTodayInDays(date) {
  const d1 = getRealDate(date);
  if ([undefined, null, false, ''].includes(d1)) {
    return false;
  }
  const today = new Date();

  return ((today.getTime() - d1.getTime()) / (1000 * 3600 * 24));
}

export {
  dateFormatShort,
  dateFormatMedium,
  dateFormatLong,
  timestampToLongDate,
  isPastDate,
  isFurtureDate,
  isMajeur,
  dateGetYear,
  dateCurrentMonthLong,
  dateCurrentMonthYear,
  getLastYear,
  getDatesDiffInDays,
  getDatesDiffTodayInDays,
};
