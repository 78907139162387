import { drupalHTTP } from '@/axios/index';
import { documentInitialState } from '@/store/modules/functions-utils';

const documentsStore = {
  state: () => (documentInitialState()),
  mutations: {
    INIT_DOCUMENTS(state) {
      state.dRefCtt = [];
      state.dErrors = [];
      state.documents = [];
    },
    ADD_ERROR(state, error) {
      state.dErrors.push(error);
    },
    CONTRAT_DOCUMENTS(state, payload) {
      state.dRefCtt = payload.refCtt;
      state.documents = payload.documents;
    },
    CONTRAT_AUTRES_ACTES_DE_GESTION(state, payload) {
      state.actesDeGestion = payload.documents;
    },
  },
  actions: {
    contrat_documents(context, refCtt) {
      context.commit('INIT_DOCUMENTS');
      drupalHTTP.get(`contrat/${refCtt}/documents`)
        .then((response) => {
          if (response.status === 200) {
            context.commit('CONTRAT_DOCUMENTS', response.data);
          } else {
            context.commit('ADD_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_ERROR', error);
        });
    },
    contrat_autres_actes_de_gestion(context) {
      drupalHTTP.get('contrat/autresactesdegestion')
        .then((response) => {
          if (response.status === 200) {
            context.commit('CONTRAT_AUTRES_ACTES_DE_GESTION', response.data);
          } else {
            context.commit('ADD_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('ADD_ERROR', error);
        });
    },
  },
  getters: {
    dRefCtt: (state) => (state.dRefCtt),
    documents: (state) => (state.documents),
    actesDeGestion: (state) => (state.actesDeGestion),
    dErrors: (state) => (state.dErrors),
  },
};

export default documentsStore;
