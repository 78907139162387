import {
  sfCustomWSHTTP,
} from '@/axios';
import {
  relationsInitialState,
} from '@/store/modules/functions-utils';

const relations = {
  state: () => relationsInitialState(),
  mutations: {
    INIT_RELATIONS_ERROR(state) {
      state.relationsErrors = [];
    },
    INIT_RELATIONS(state) {
      state.relations = [];
    },
    ADD_RELATIONS_ERROR(state, error) {
      state.relationsErrors.push(error);
    },
    LOAD_RELATIONS(state, payload) {
      state.relations = payload;
    },

    INIT_ACCOUNT_RELATIONS_ERROR(state) {
      state.accountRelationsErrors = [];
    },
    INIT_ACCOUNT_RELATIONS(state) {
      state.accountRelations = [];
    },
    ADD_ACCOUNT_RELATIONS_ERROR(state, error) {
      state.accountRelationsErrors.push(error);
    },
    LOAD_ACCOUNT_RELATIONS(state, payload) {
      state.accountRelations = payload;
    },
  },
  actions: {
    load_client_relations(context, args) {
      context.commit("INIT_RELATIONS");
      context.commit("INIT_RELATIONS_ERROR");

      sfCustomWSHTTP
        .get(`${args.idFicheContact}/relations/?offset=${args.offset}&limit=${args.limit}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("LOAD_RELATIONS", response.data);
          } else {
            context.commit("ADD_RELATIONS_ERROR", response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit("ADD_RELATIONS_ERROR", error.response.data);
          } else {
            context.commit("ADD_RELATIONS_ERROR", error);
          }
        });
    },
    load_client_account_relations(context, args) {
      context.commit("INIT_ACCOUNT_RELATIONS");
      context.commit("INIT_ACCOUNT_RELATIONS_ERROR");

      sfCustomWSHTTP
        .get(`${args.idFicheContact}/accountrelationships/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("LOAD_ACCOUNT_RELATIONS", response.data);
          } else {
            context.commit("ADD_ACCOUNT_RELATIONS_ERROR", response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit("ADD_ACCOUNT_RELATIONS_ERROR", error.response.data);
          } else {
            context.commit("ADD_ACCOUNT_RELATIONS_ERROR", error);
          }
        });
    },
  },
  getters: {
    relationsErrors: (state) => state.relationsErrors,
    totalOfRelations: (state) => state.relations.total,
    sizeRelations: (state) => state.relations.size,
    relations: (state) => state.relations.results,
    accountRelationsErrors: (state) => state.accountRelationsErrors,
    accountRelations: (state) => state.accountRelations,
  },
};

export default relations;
