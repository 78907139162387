<template>
  <div
    class="col-sm-3 col-half wow fadeInUp"
    data-wow-duration="1.75s"
    style="visibility: visible; animation-duration: 1.75s; animation-name: fadeInUp;"
  >
    <div :class="mainClassName">
      <ul
        class="footer-menu"
        :class="className"
      >
        <li
          v-for="(item, index) in items"
          :key="index"
          class="leaf"
          :class="{ first: (index === 0), last: ((items.length - 1) === index) }"
        >
          <a
            :href="item.to"
            :title="item.title"
            class="active-trail active"
            target="_blank"
          >
            {{ item.slot }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';

export default {
  name: 'FooterMenu',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    mainClassName: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
