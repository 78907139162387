<template>
  <div>
    <footer id="footer">
      <div class="container">
        <div class="row">
          <footer-menu
            :items="footerFirstMenuItems"
            main-class-name="footer-main-menu"
            class-name="primary-menu"
          />
          <footer-menu
            :items="footerSecondMenuItems"
            main-class-name="footer-sub-menu"
            class-name="secondary-menu"
          />
          <footer-contact :items="footerThirdMenuItems" />
          <div
            v-if="isBrowser"
            class="col-sm-3 wow fadeInUp"
            data-wow-duration="1.75s"
            style="visibility: visible; animation-duration: 1.75s; animation-name: fadeInUp;"
          >
            <div class="logo-hedios">
              <img
                alt="Hedios - Créateur des Gammes H"
                width="217"
                height="216"
                class="img-responsive lazy"
                style="display: inline-block;"
                :src="logoFooter"
              >
            </div>
          </div>
        </div>
        <div
          class="row-copyright wow fadeInUp"
          data-wow-duration="1.75s"
          style="visibility: visible; animation-duration: 1.75s; animation-name: fadeInUp;"
        >
          <div class="col-sm-3 pull-right">
            <p class="copyright">
              {{ $t('copyright', { currentYear }) }}
            </p>
          </div>
        </div>
        <div
          class="rowpull-left wow fadeInUp"
          data-wow-duration="1.75s"
          style="visibility: visible; animation-duration: 1.75s; animation-name: fadeInUp;"
        >
          <p
            class="copyright text-justify"
            v-html="$t('footer.text1')"
          />
          <p
            class="copyright text-justify"
            v-html="$t('footer.text2')"
          />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { isMobile, isBrowser } from 'mobile-device-detect';
import FooterMenu from '@/components/footer/FooterMenu.vue';
import FooterContact from '@/components/footer/FooterContact.vue';

import footerFirstMenuItems from '@/data/footer-first-menu';
import footerSecondMenuItems from '@/data/footer-second-menu';
import footerThirdMenuItems from '@/data/footer-third-menu';

export default {
  name: 'HediosFooter',
  components: {
    'footer-menu': FooterMenu,
    'footer-contact': FooterContact,
  },
  data() {
    return {
      isMobile,
      isBrowser,
      footerFirstMenuItems,
      footerSecondMenuItems,
      footerThirdMenuItems,
      logoFooter: `${process.env.VUE_APP_DRUPAL_BASE_URL}/sites/all/themes/gammesh/img/logo-foot.svg`,
    };
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
  },
};
</script>

<style lang="scss">
  #footer {
  background-color: $accent-8;
  padding: 50px 0;
  border-top: 4px solid $accent-2;
  overflow: hidden;
  > .container {
    max-width: 1160px;
  }
  a {
    color: $white;
    text-decoration: none;
  }
  ul {
    display: inline-block;
    padding: 0 15px;
  }
  h2 {
    font-size: 1em;
    text-align: center;
    color: $white;
    padding-bottom: 15px;
  }
  .footer {
    &-main-menu {
      text-transform: uppercase;
      ul {
        float: left;
        width: 100%;
        padding-left: 40px;
        &:before {
          font-family: $font-icon;
          position: absolute;
          content: "\e902";
          left: 10px;
          top: -6px;
          color: $white;
          font-size: 2em;
        }
      }
      li {
        display: inline-block;
        width: 100%;
        padding: 4px 0;
      }
    }
    &-sub-menu {
      overflow: hidden;
      margin: 0 auto;
      width: 235px;
      li {
        display: inline-block;
        width: 100%;
        padding: 4px 0;
      }
    }
    &-contact-menu {
      text-align: center;
      li {
        display: inline-block;
        padding: 0 8px;
      }
      ul {
        padding: 0;
        + h2 {
          padding-top: 20px;
          margin-top: 25px;
          border-top: 1px solid #99a2a9;
          @media screen and (max-width: $breakpoint-s) {
            padding-top: 0;
            border: none;
            margin: 15px 0 0 0;
          }
        }
      }
      i {
        font-size: 1.85em;
      }
    }
  }
  .col-sm-3 {
    position: relative;
    min-height: 185px;
    &:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #99a2a9;
      position: absolute;
      left: 0;
      top: 0;
      min-height: 235px;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    &.pull-right {
      min-height: 1px;
    }
  }
  .logo-hedios {
    opacity: 0.25;
    text-align: center;
    img {
      display: inline-block;
      max-width: 185px;
    }
  }
  .copyright {
    text-align: center;
    background: none;
    padding: 10px 0 0 0;
    color: $white;
    font-size: 0.75em;
  }
  .row-copyright {
    .copyright {
      margin: -10px 0 0 0;
      padding: 0;
    }
  }
  .rowpull-left {
    margin-top: 50px;
    padding-top: 0;
    border-top: 1px solid #99a2a9;
    .copyright {
      text-align: left;
      font-size: 0.975em;
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    padding: 0 20px;
    > .container {
      > .row {
        > .col-sm-3 {
          &:last-child {
            padding: 0;
          }
        }
      }
    }
    .col-sm-3 {
      padding: 20px 0;
      display: inline-block;
      width: 100%;
      min-height: 1px;
      &.pull-right {
        padding: 0;
      }
      &:before {
        height: 1px;
        width: 100%;
        background-color: $white;
        min-height: 1px;
      }
    }
    .col-half {
      width: 50%;
      float: left;
      padding-left: 0;
      + .col-half {
        &:before {
          display: none;
        }
        ul {
          padding: 35px 0 0 15px;
          position: relative;
          min-height: 200px;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            height: 75%;
            top: 45px;
            background-color: $white;
          }
        }
      }
      ul {
        padding: 35px 15px 0 0;
      }
    }
    .footer {
      &-main-menu {
        ul {
          &:before {
            left: calc(100% - 15px);
            top: 10px;
          }
        }
      }
    }
    .logo-hedios, .copyright {
      display: none;
    }
    .rowpull-left {
      padding-bottom: 60px;
      border: none;
      margin: 0 -15px;
      p {
        display: inline-block;
      }
    }
  }

}
</style>
